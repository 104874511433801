import { Router } from "@reach/router";
import React from "react";
import { I18n } from "react-redux-i18n";
import Panel from "./Panel";
import Public from "./Public";
import Login from "../../containers/login";
import Users from "../../containers/panel/users";
import Organizers from "../../containers/panel/organizers/Organizers";
import OrganizerDetails from "../../containers/panel/organizerDetails/OrganizerDetails";
import Events from "../../containers/panel/events/Events";
import EventDetails from "../../containers/panel/eventDetails/EventDetails";
import Promoters from "../../containers/panel/promoters";
import PromoterDetails from "../../containers/panel/promoterDetails";
import PushNotification from "../../containers/panel/pushNotification";
import PurchaseDetails from "../../containers/panel/purchaseDetails/PurchaseDetails";

import Administrators from "../../containers/panel/administrators";
import AdministratorDetails from "../../containers/panel/administratorDetails";
import AskForAppToMobile from "../../containers/AskForAppToMobile/AskForAppToMobile";
import Coupons from "../../containers/panel/coupons/Coupons";
import CouponDetails from "../../containers/panel/couponDetails/CouponDetails";

class Content extends React.PureComponent {
  render() {
    return (
      <Router>
        <Public
          path={I18n.t("routes.login.url")}
          container={<Login />}
          title={`${I18n.t("routes.login.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Public
          path={I18n.t("routes.askToMobile.url")}
          container={<AskForAppToMobile />}
          title={`${I18n.t("routes.askToMobile.pageTitle")} - ${I18n.t("application.name")}`}
        />

        <Panel
          path={I18n.t("routes.panel.administrators.url")}
          container={<Administrators />}
          title={`${I18n.t("routes.panel.administrators.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.administratorDetails.url")}:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t("routes.panel.administratorDetails.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.administratorDetails.url")}add`}
          container={<AdministratorDetails />}
          title={`${I18n.t("routes.panel.administratorDetails.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={I18n.t("routes.panel.users.url")}
          container={<Users />}
          title={`${I18n.t("routes.panel.users.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={I18n.t("routes.panel.organizers.url")}
          container={<Organizers />}
          title={`${I18n.t("routes.panel.organizers.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.organizerDetails.url")}add`}
          container={<OrganizerDetails />}
          title={`${I18n.t("routes.panel.organizers.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.organizerDetails.url")}:id`}
          container={<OrganizerDetails />}
          title={`${I18n.t("routes.panel.organizers.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={I18n.t("routes.panel.events.url")}
          container={<Events />}
          title={`${I18n.t("routes.panel.events.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.events.url")}add`}
          container={<EventDetails />}
          title={`${I18n.t("routes.panel.events.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.events.url")}:id`}
          container={<EventDetails />}
          title={`${I18n.t("routes.panel.events.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={I18n.t("routes.panel.promoters.url")}
          container={<Promoters />}
          title={`${I18n.t("routes.panel.promoters.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.promoters.url")}add`}
          container={<PromoterDetails />}
          title={`${I18n.t("routes.panel.promoters.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.promoters.url")}:id`}
          container={<PromoterDetails />}
          title={`${I18n.t("routes.panel.promoters.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.purchases.url")}`}
          container={<PurchaseDetails />}
          title={`${I18n.t("routes.panel.purchases.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.purchases.url")}:id`}
          container={<PurchaseDetails />}
          title={`${I18n.t("routes.panel.purchases.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("pushNotification.url")}`}
          container={<PushNotification />}
          title={`${I18n.t("pushNotification.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={I18n.t("routes.panel.discountCoupon.url")}
          container={<Coupons />}
          title={`${I18n.t("routes.panel.discountCoupon.pageTitle")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.discountCoupon.url")}/add`}
          container={<CouponDetails />}
          title={`${I18n.t("routes.panel.discountCouponDetails.pageTitleAdd")} - ${I18n.t("application.name")}`}
        />
        <Panel
          path={`${I18n.t("routes.panel.discountCoupon.url")}/:id`}
          container={<CouponDetails />}
          title={`${I18n.t("routes.panel.discountCouponDetails.pageTitle")} - ${I18n.t("application.name")}`}
        />
      </Router>
    );
  }
}

export default Content;
