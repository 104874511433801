export const CouponDiscounType = {
  PERCENTAGE: 1,
  VALUE: 2,
};

export const CouponDurationType = {
  NO_PERIOD: 1,
  EXPIRABLE: 2,
};

export const CouponQuantityType = {
  UNLIMITED: 1,
  LIMITED: 2,
};

export const EventsScope = {
  ALL_EVENTS: 1,
  UNIC_EVENT: 2,
};