/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Steps,
  Checkbox,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  RightOutlined, SaveOutlined, PlusCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
import {
  UtilsActions, PromoterActions, UserActions, OrganizerActions,
} from '../../redux/actions/index';
import {
  AuthSelectors, LoadingSelectors, EventSelectors, OrganizerSelectors, UserSelectors,
} from '../../redux/reducers';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import AdvancedRangePicker from '../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import { removeSpecialChars, validateEmail } from '../../utils/string';
import { ViewType } from '../../enum/viewType';
import { ProfileType } from '../../enum/profileType';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      formEventHeight: 0,
      formTicketsHeight: 0,
      formPromotersHeight: 0,
      promoterInputValue: '',
      form: {
        title: '',
        description: '',
        artiste: '',
        address: '',
        cep: '',
        city: '',
        uf: '',
        district: '',
        number: '',
        namePlace: '',
        dateEventInitial: '',
        dateEventFinal: '',
        dateSpotlightInitial: '',
        dateSpotlightFinal: '',
        eventType: 1,
        promoterId: '',
        isActive: true,
        ticketsTypes: [{
          type: '',
          quantity: null,
          price: 0,
          paused: false,
          editable: false,
        }],
        operatorEvent: [],
        isSpotlight: false,
      },
    };
  }

  async componentDidMount() {
    const formEventHeight = this.formPersonal.clientHeight;
    this.setState({ formEventHeight });

    const { data, getUsersPaginated, getOrganizers } = this.props;
    if (data) {
      this.setState({
        form: {
          title: data.title,
          description: data.description,
          artiste: data.artiste,
          address: data.address && data.address.street,
          zipCode: data.address && data.address.zipCode,
          uf: data.address && data.address.uf,
          city: data.address && data.address.city,
          number: data.address && data.address.number,
          district: data.address && data.address.district,
          namePlace: data.address && data.address.namePlace,
          dateEventInitial: moment(data.dateEventInitial),
          dateEventFinal: moment(data.dateEventFinal),
          dateSpotlightInitial: data.dateSpotlightInitial ? moment(data.dateSpotlightInitial) : moment(),
          dateSpotlightFinal: data.dateSpotlightFinal ? moment(data.dateSpotlightFinal) : moment(),
          ticketsTypes: data.ticketsTypes,
          operatorEvent: data.operatorEvent || [],
          pictures: data.pictures,
          maximumCapacity: data.maximumCapacity,
          isActive: data.isActive,
        },
      }, () => {
        this.fieldChange('eventType', data.eventType);
        this.fieldChange('promoterId', data.promoterId);
        if (moment(data.dateSpotlightInitial).isValid()
          || moment(data.dateSpotlightFinal).isValid()) {
          this.fieldChange('isSpotlight', true);
        }
      });
    }
    const parameters = {
      limit: 100,
      profileType: ProfileType.PROMOTER,
    };
    await getUsersPaginated(parameters);
    await getOrganizers();
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;

    const { data, submitFunction } = this.props;
    const obj = {
      title: form.title,
      description: form.description,
      artiste: form.artiste,
      dateEventInitial: form.dateEventInitial,
      dateEventFinal: form.dateEventFinal,
      dateSpotlightInitial: form.isSpotlight ? form.dateSpotlightInitial : null,
      dateSpotlightFinal: form.isSpotlight ? form.dateSpotlightFinal : null,
      eventType: form.eventType,
      promoterId: form.promoterId,
      maximumCapacity: form.maximumCapacity,
      address: {
        zipCode: removeSpecialChars(form.zipCode),
        countryName: 'Brasil',
        uf: form.uf,
        city: form.city,
        district: form.district,
        street: form.address,
        number: form.number,
        namePlace: form.namePlace,
      },
      isActive: form.isActive,
      ticketsTypes: form.ticketsTypes && form.ticketsTypes.map((t) => ({
        ...t,
        price: t.price,
      })),
      operatorEvent: form.operatorEvent && form.operatorEvent.map((i) => ({
        operatorId: i.operator ? i.operator.id : i.id,
      })),
    };
    if (form.pictures && form.pictures[0] && form.pictures[0].file) obj.pictures = form.pictures;

    if (obj?.title.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldEventName'));
    }
    if (obj?.description?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldEventDescription'));
    }
    if (obj?.artiste?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldEventArtiste'));
    }
    if (obj?.eventType?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldEventType'));
    }
    if (obj?.maximumCapacity?.length === 0 || obj?.maximumCapacity === undefined) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldMaximumCapacity'));
    }
    if (obj?.address?.namePlace?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldEventNamePlace'));
    }
    if (obj?.address?.zipCode?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.countryName?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.uf?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.city?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.district?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.street?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.address?.number?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldAddress'));
    }
    if (obj?.dateEventInitial?.length === 0 || obj?.dateEventInitial === 'Invalid date') {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldDateEventInitial'));
    }
    if (obj?.dateEventFinal?.length === 0 || obj?.dateEventFinal === 'Invalid date') {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldDateEventFinal'));
    }
    if (obj?.promoterId?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldPromoterId'));
    }
    if (obj?.ticketsTypes[0]?.type?.length === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldTicketsTypesType'));
    }
    if (obj?.ticketsTypes[0]?.quantity === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldTicketsTypesQuantity'));
    }
    if (obj?.ticketsTypes[0]?.price === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldTicketsTypesPrice'));
    }
    if (form?.promoterInputValue === 0) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.fillFieldPromoterInputValue'));
    }

    if (!moment(obj.dateEventInitial).isValid() || (moment(obj.dateEventInitial) < moment().toDate())) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.dateEventInitial'));
    } 

    if (!moment(obj.dateEventFinal).isValid() || (moment(obj.dateEventFinal) < moment().toDate()) ) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.dateEventFinal'));
    } 

    if (form.isSpotlight && !moment(obj.dateSpotlightInitial).isValid()
        || (moment(obj.dateSpotlightInitial) < moment().toDate())) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.dateSpotlightInitial'));
    } 

    if (form.isSpotlight && !moment(obj.dateSpotlightFinal).isValid()
      || (moment(obj.dateSpotlightFinal) < moment().toDate()) ) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.dateSpotlightFinal'));
    } 
    
    if (!data && (!form.pictures)) {
      return message.error(I18n.t('routes.panel.eventDetails.messages.errors.invalid_pictures'));
    }
    submitFunction(data ? data.id : null, obj);
  }

  onRemoveNewImage() {
    const { data } = this.props;
    const { pictures } = data;

    if (data && pictures) {
      const { form } = this.state;
      form.pictures = pictures;
      this.setState({ form });
    }
  }

  removeTicket = (indexTicket) => {
    const { form } = this.state;
    form.ticketsTypes = form.ticketsTypes.filter((ticket, index) => index !== indexTicket);
    this.setState({
      form,
    });
  }

  addNewPromotor = async () => {
    const { form, promoterInputValue } = this.state;
    if (!validateEmail(promoterInputValue)) {
      return message.error(I18n.t('routes.panel.promoterDetails.messages.errors.email'));
    }

    const promoter = await this.searchPromotorByEmail(promoterInputValue);
    if (promoter) {
      form.operatorEvent.push(promoter);
      this.setState({
        form,
        promoterInputValue: '',
      });
    } else {
      message.error(I18n.t('routes.panel.eventDetails.messages.errors.noPromoterFound'));
    }
  }

  searchPromotorByEmail = async (email) => {
    const promoter = await this.props.getPromoterByEmail(email);
    if (promoter) return promoter;
    return null;
  }

  removePromoter = (indexPromoter) => {
    const { form } = this.state;
    form.operatorEvent = form.operatorEvent.filter((ticket, index) => index !== indexPromoter);
    this.setState({
      form,
    });
  }

  ticketChange = (index, field, value) => {
    const { form } = this.state;
    form.ticketsTypes[index][field] = value;
    this.setState({ form });
  }

  addNewTicket = () => {
    const { form } = this.state;
    form.ticketsTypes.push({
      type: '',
      price: 0,
      quantity: null,
      paused: false,
      editable: true,
    });
    this.setState({
      form,
    });
  }

  getAddressByCep = async (cep) => {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    this.fieldChange('uf', completeAddress.uf);
    this.fieldChange('city', completeAddress.localidade);
    this.fieldChange('address', completeAddress.logradouro);
    this.fieldChange('district', completeAddress.bairro);
  }

  nextStep = () => {
    this.setState((state) => ({
      current: state.current + 1,
    }), () => {
      if (this.state.current >= 1) {
        this.setState({ formTicketsHeight: this.formTicket.clientHeight });
      }
      if (this.state.current >= 2) {
        this.setState({ formPromotersHeight: this.formPromoters.clientHeight });
      }
    });
  }

  changeStep = (current) => {
    this.setState({ current }, () => {
      if (current === 0) {
        this.setState({ formTicketsHeight: 0 });
      }
    });
  };

  customRequest = async (file) => {
    try {
      const pictures = [
        { order: 1, file: file.base64 },
      ];
      this.fieldChange('pictures', pictures);
    } catch (error) {
      //
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const { form, current } = this.state;
    const {
      eventTypes, viewType, organizers, usersPaginated,
    } = this.props;
    const { Step } = Steps;
    const imgPreview = (form && form.pictures && form.pictures[0] && (form.pictures[0].url || form.pictures[0].file));
    const showDeleteNewImageBtn = (form && form.pictures && form.pictures[0] && form.pictures[0].order) && viewType === ViewType.EDIT;
    return (
      <Row className="user-form-row">
        <Col
          span={6}
          className="user-form-col"
        >
          <Steps
            direction="vertical"
            size="small"
            current={current}
            onChange={this.changeStep}
            className="user-form-steps"
          >
            <Step
              title="Dados do evento"
              className="user-form-step"
              style={{ minHeight: this.state.formEventHeight }}
            />
            <Step
              title="Dados dos ingressos"
              className="user-form-step"
              style={{ minHeight: this.state.formTicketsHeight }}
            />
            <Step
              title="Promotores"
              className="user-form-step"
              style={{ minHeight: this.state.formPromotersHeight }}
            />
            <Step
              icon={<div />}
              tz
              className="user-form-step"
            />
          </Steps>
        </Col>
        <Col span={18}>
          <form
            name="userForm"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            {current >= 0
              ? (
                <div
                  ref={(ref) => { this.formPersonal = ref; }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <div
                        ref={(ref) => { this.formFiles = ref; }}
                      >
                        <AdvancedDragger
                          title={I18n.t('routes.panel.eventDetails.uploadPictureButton')}
                          accept=".jpg, .jpeg, .png"
                          fileList={this.state.pdfFileName}
                          label={I18n.t('forms.event.addImage.label')}
                          progress={this.state.imageProgress}
                          customRequest={(file) => this.customRequest(file)}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      {(form && form.pictures) && (
                        <div style={{
                          backgroundImage: `url(${imgPreview})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          width: '100%',
                          height: '100%',
                        }}
                        >
                          {showDeleteNewImageBtn && (
                            <AdvancedButton
                              htmlType="button"
                              icon={<CloseCircleOutlined />}
                              onClick={() => this.onRemoveNewImage()}
                              className="btn-absolute"
                            />
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.event.title.label')}
                        value={form && form.title}
                        onChange={(val) => this.fieldChange('title', val)}
                        maxLength={80}
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.event.description.label')}
                        value={form && form.description}
                        onChange={(val) => this.fieldChange('description', val)}
                        maxLength={3000}
                        isTextArea
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.event.artiste.label')}
                        value={form && form.artiste}
                        onChange={(val) => this.fieldChange('artiste', val)}
                        maxLength={50}
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedSelect
                        defaultValue={form && form.eventType}
                        value={form.eventType}
                        onChange={(val) => {
                          this.fieldChange('eventType', val);
                        }}
                        options={eventTypes}
                        label={I18n.t('forms.event.eventType.label')}
                        labelStyle={{ paddingBottom: 5 }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.event.maximumCapacity.label')}
                        value={form && form.maximumCapacity}
                        onChange={(val) => this.fieldChange('maximumCapacity', val)}
                        maxLength={8}
                        type="number"
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.namePlace.label')}
                        value={form && form.namePlace}
                        onChange={(val) => this.fieldChange('namePlace', val)}
                        maxLength={80}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.event.cep.label')}
                        value={form && form.zipCode}
                        onChange={(val) => {
                          this.fieldChange('zipCode', val);
                          if (val && removeSpecialChars(val).length === 8) {
                            this.getAddressByCep(removeSpecialChars(val));
                          }
                        }}
                        mask="99999-999"
                        type="tel"
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.uf.label')}
                        value={form && form.uf}
                        onChange={(val) => this.fieldChange('uf', val)}
                        maxLength={2}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.city.label')}
                        value={form && form.city}
                        onChange={(val) => this.fieldChange('city', val)}
                        maxLength={80}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.address.label')}
                        value={form && form.address}
                        onChange={(val) => this.fieldChange('address', val)}
                        maxLength={80}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.number.label')}
                        value={form && form.number}
                        onChange={(val) => this.fieldChange('number', val)}
                        maxLength={80}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('forms.event.neighborhood.label')}
                        value={form && form.district}
                        onChange={(val) => this.fieldChange('district', val)}
                        maxLength={80}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedRangePicker
                        label={I18n.t('forms.event.date.label')}
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        onChange={(val) => this.fieldChange('dateEventInitial', val)}
                        value={form && form.dateEventInitial}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedRangePicker
                        label={I18n.t('forms.event.dateEnd.label')}
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        onChange={(val) => this.fieldChange('dateEventFinal', val)}
                        value={form && form.dateEventFinal}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedSelect
                        defaultValue={form && form.promoterId}
                        value={form.promoterId}
                        onChange={(val) => {
                          this.fieldChange('promoterId', val);
                        }}
                        options={organizers}
                        label={I18n.t('forms.event.organizer.label')}
                        labelStyle={{ paddingBottom: 5 }}
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={16}
                    className="form-password-divider"
                  >
                    <Col span={8}>
                      <Checkbox
                        checked={form && form.isActive}
                        onChange={(e) => this.fieldChange('isActive', e.target.checked)}
                      >
                        {I18n.t('forms.event.isActive.label')}
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        checked={form && form.isSpotlight}
                        onChange={(e) => this.fieldChange('isSpotlight', e.target.checked)}
                      >
                        {I18n.t('forms.event.isSpotlight.label')}
                      </Checkbox>
                    </Col>
                  </Row>

                  {form && form.isSpotlight && (
                    <>
                      <Row gutter={16}>
                        <Col span={8}>
                          <AdvancedRangePicker
                            label={I18n.t('forms.event.dateSpotlight.label')}
                            showTime={{ format: 'HH:mm' }}
                            format="DD/MM/YYYY HH:mm"
                            onChange={(val) => this.fieldChange('dateSpotlightInitial', val)}
                            value={form && form.dateSpotlightInitial}
                          />
                        </Col>
                        <Col span={8}>
                          <AdvancedRangePicker
                            label={I18n.t('forms.event.dateSpotlightEnd.label')}
                            showTime={{ format: 'HH:mm' }}
                            format="DD/MM/YYYY HH:mm"
                            onChange={(val) => this.fieldChange('dateSpotlightFinal', val)}
                            value={form && form.dateSpotlightFinal}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Divider />

                  <Row>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.submitButtonText')}
                        icon={<RightOutlined />}
                        onClick={this.nextStep}
                        disabled={current > 0}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

            {current >= 1
              ? (
                <div
                  ref={(ref) => { this.formTicket = ref; }}
                >
                  {form && form.ticketsTypes && form.ticketsTypes.map((item, index) => (
                    <Fragment key={index.toString()}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <p style={{ fontWeight: 'bold' }}>Ingresso {index + 1}</p>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <AdvancedInput
                            label={I18n.t('forms.event.ticketType.label')}
                            value={item.type}
                            onChange={(val) => this.ticketChange(index, 'type', val)}
                            maxLength={80}
                          />
                        </Col>
                        <Col span={7}>
                          <AdvancedInput
                            label={I18n.t('forms.event.ticketAvailableAmount.label')}
                            value={item.quantity}
                            onChange={(val) => this.ticketChange(index, 'quantity', val)}
                            maxLength={8}
                            type="number"
                          />
                        </Col>
                        <Col span={7}>
                          <AdvancedInput
                            label={I18n.t('forms.event.ticketPrice.label')}
                            value={item.price * 1}
                            onChange={(val) => this.ticketChange(index, 'price', val)}
                            maxLength={8}
                            isMoney
                            disabled={viewType === ViewType.EDIT && !item.editable}
                          />
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {((viewType === ViewType.ADD) || (!item.id)) && (
                            <AdvancedButton
                              htmlType="button"
                              icon={<CloseCircleOutlined />}
                              onClick={() => this.removeTicket(index)}
                            />
                          )}
                        </Col>
                      </Row>
                      <Col span={6}>
                        <Checkbox
                          checked={item.paused}
                          onChange={(e) => this.ticketChange(index, 'paused', e.target.checked)}
                          style={{ marginBottom: '15px' }}
                        >
                          {I18n.t('forms.event.pauseSells.label')}
                        </Checkbox>
                      </Col>
                    </Fragment>
                  ))}
                  <Row gutter={16}>
                    <Col
                      span={24}
                      className="text-left"
                    >
                      <AdvancedButton
                        htmlType="button"
                        text={I18n.t('routes.panel.administrators.addNewButtonText')}
                        icon={<PlusCircleOutlined />}
                        onClick={this.addNewTicket}
                      />
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.submitButtonText')}
                        icon={<RightOutlined />}
                        onClick={this.nextStep}
                        disabled={current > 1}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

            {current >= 2 ? (
              <div
                ref={(ref) => { this.formPromoters = ref; }}
              >
                <Row>
                  <Col span={8}>
                    <AdvancedSelect
                      defaultValue={form && form.promoterInputValue}
                      value={this.state.promoterInputValue}
                      onChange={(e) => this.setState({ promoterInputValue: e })}
                      options={usersPaginated.rows}
                      label={I18n.t('forms.event.addPromoter.label')}
                      labelStyle={{ paddingBottom: 5 }}
                      showEmail
                    />
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '10px',
                      paddingLeft: '10px',
                    }}
                  >
                    <AdvancedButton
                      htmlType="button"
                      text={I18n.t('routes.panel.administrators.addNewButtonText')}
                      icon={<PlusCircleOutlined />}
                      onClick={this.addNewPromotor}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {form && form.operatorEvent && form.operatorEvent.map((item, index) => (
                      <Row
                        gutter={16}
                        key={index.toString()}
                      >
                        <Col span={22}>
                          <AdvancedInput
                            value={(item.operator && item.operator.email) || item.email}
                            disabled
                          />
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '10px',
                          }}
                        >
                          <AdvancedButton
                            htmlType="button"
                            icon={<CloseCircleOutlined />}
                            onClick={() => this.removePromoter(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={24}
                    className="text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('forms.goBackButtonText')}
                      href={I18n.t('routes.panel.users.url')}
                    />
                    <Divider
                      className="form-button-divider"
                      type="vertical"
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.save')}
                      icon={<SaveOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            )
              : null}
          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  eventTypes: EventSelectors.getEventTypes(state),
  organizers: OrganizerSelectors.getOrganizers(state),
  usersPaginated: UserSelectors.getUsersPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAddressByCep: (cep) => dispatch(
    UtilsActions.getAddressByCep(cep),
  ),
  getPromoterByEmail: (email) => dispatch(
    PromoterActions.getPromoterByEmail(email),
  ),
  getUsersPaginated: (parameters) => dispatch(UserActions.getUsersPaginated(parameters)),
  getOrganizers: () => dispatch(OrganizerActions.getOrganizers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  EventForm,
);
