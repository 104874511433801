import getInstance from './config';

export default class EventApi {
  static async getEventsPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/financial-report', { params });
    return data;
  }

  static async getEventById(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/event/${id}?getOperator=true`);
    return data;
  }

  static async createEvent(body) {
    const instance = await getInstance();
    const { data } = await instance.post('/event', body);
    return data;
  }

  static async updateEvent(id, body) {
    const instance = await getInstance();
    const { data } = await instance.put(`/event/${id}`, body);
    return data;
  }

  static async updateTicket(body, id) {
    const instance = await getInstance();
    const { data } = await instance.put(`/ticket-type/${body.id}`, {
      ticketType: body.type, price: body.price, eventId: id,
    });

    return data;
  }

  static async deleteEvent(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/event/${id}`);
    return data;
  }

  static async getEventTypes() {
    const instance = await getInstance();
    const { data } = await instance.get('/utils/event-type', {});
    return data;
  }

  static async getEventSales(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user-payment/sales', { params });
    return data;
  }

  static async getEventSalesDetails(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/financial-report/detail', { params });
    return data;
  }

  static async setRefund(id, amount) {
    const instance = await getInstance();
    const { data } = await instance.post(`/user-payment/refund/${id}`, { amount });
    return data;
  }
}
