import React from 'react';
import {
  DatePicker,
} from 'antd';

export const dateFormat = 'DD/MM/YYYY';

const AdvancedRangePicker = (
  {
    label,
    showTime,
    format,
    onChange,
    value,
    placeholder,
  },
) => (
  <div className="advanced-rangepicker">
    <label className="advanced-rangepicker__label">
      <span className="advanced-rangepicker__label__inner">{label}</span>
      <DatePicker
        format={format}
        onChange={onChange}
        showTime={showTime}
        value={value}
        placeholder={placeholder}
        style={
          { width: '100%' }
        }
      />
    </label>
  </div>
);

export default AdvancedRangePicker;
