import React from 'react';
import {
  Upload,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { InboxOutlined } from '@ant-design/icons';

import { compressImages } from '../../../app/utils/imageUtils';
import { getBase64 } from '../../../app/utils/string';

const { Dragger } = Upload;

const AdvancedDragger = (
  {
    title,
    multiple,
    accept,
    fileList,
    onRemove,
    label,
    customRequest,
  },
) => {
  const onFileChange = async ({ file }) => {
    if (file) {
      if (file.type !== 'image/png') {
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }
      customRequest(file);
    } else {
      // onChange(null);
    }
  };

  return (
    <div style={{ marginBottom: 50 }}>
      <Dragger
        name="file"
        multiple={multiple || false}
        customRequest={(ev) => onFileChange(ev)}
        accept={accept}
        fileList={fileList ? [fileList] : []}
        onRemove={onRemove}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{title || I18n.t('forms.content.addFiles')}</p>
        <p className="ant-upload-hint">
          {label}
        </p>
      </Dragger>

    </div>
  );
};

export default AdvancedDragger;
