import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import couponReducer from './reducers/coupons';
import promoterReducer from './reducers/promoter';
import organizerReducer from './reducers/organizer';
import eventReducer from './reducers/event';
import categoryReducer from './reducers/category';
import loadingReducer from './reducers/loading';
import pushNotificationReducer from './reducers/push-notification';

export default () => {
  const store = createStore(
    combineReducers(
      {
        i18n: i18nReducer,
        auth: authReducer,
        loading: loadingReducer,
        user: userReducer,
        coupon: couponReducer,
        category: categoryReducer,
        organizer: organizerReducer,
        event: eventReducer,
        promoter: promoterReducer,
        pushNotification: pushNotificationReducer,
      },
    ),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  return store;
};
