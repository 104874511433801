/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
  DatePicker,
  Select,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import {
  AuthSelectors, LoadingSelectors, PromoterSelectors, UserSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput';

import { GenderTypeEnum } from '../../enum/genderType';
import { ViewType } from '../../enum/viewType';
import { removeSpecialChars, validateEmail } from '../../utils/string';
import * as DateUtils from '../../utils/date';

class PromoterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        birthday: '',
        gender: 1,
        cellphone: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(_nextProps, _nextContext) {
    const {
      userDetails,
      id,
    } = this.props;
    if (userDetails && (id && id !== 'add')) {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: userDetails && userDetails.name,
          email: userDetails && userDetails.email,
          password: null,
          passwordConfirmation: null,
          birthday: moment(userDetails.birthday),
          gender: userDetails && userDetails.gender,
          cellphone: userDetails && userDetails.cellphone,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { viewType, data } = this.props;

    if (!validateEmail(form.email)) {
      return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.email'));
    }

    if (!DateUtils.isValid(form.birthday, true)) {
      return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.date'));
    }

    const userSchema = yup.object().shape({
      name: yup.string().required(),
      email: yup.string().required(),
      birthday: yup.date().required(),
      gender: yup.number().required(),
      cellphone: yup.string().required(),
    });

    userSchema.isValid(form).then((valid) => {
      if (!valid) message.error(I18n.t('routes.panel.administratorDetails.messages.errors.generic'));
      else {
        if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
          if (!(form.password && form.passwordConfirmation)) {
            return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsRequired'));
          } if (form.password !== form.passwordConfirmation) {
            return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsMismatch'));
          }
        }

        if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
          delete form.password;
          delete form.passwordConfirmation;
        }

        const obj = {
          ...form,
          cellphone: removeSpecialChars(form.cellphone),
          birthday: moment(form.birthday).format('YYYY-MM-DD'),
          grantType: 'password',
        };
        this.props.submitFunction(data ? data.id : null, obj);
      }
    });
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading, viewType,
    } = this.props;
    const { form, changePasswordVisible } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="administratorForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('forms.promoter.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                    maxLength={80}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('forms.promoter.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                    maxLength={80}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={5}>
                  <div style={{ paddingBottom: 5 }}>
                    {I18n.t('forms.promoter.birthdate.label')}
                  </div>
                  <DatePicker
                    placeholder=""
                    // showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY"
                    onChange={(val) => this.fieldChange('birthday', val)}
                    style={{ minWidth: '100%' }}
                    value={form && form.birthday}
                  />
                </Col>
                <Col span={5}>
                  <div style={{ paddingBottom: 5 }}>
                    {I18n.t('forms.promoter.gender.label')}
                  </div>
                  <Select
                    defaultValue={form && form.status}
                    value={form && form.gender}
                    style={{ minWidth: '100%' }}
                    onChange={(val) => this.fieldChange('gender', val)}
                  >
                    <Select.Option
                      value={GenderTypeEnum[1].id}
                    >
                      {GenderTypeEnum[1].name}
                    </Select.Option>
                    <Select.Option
                      value={GenderTypeEnum[2].id}
                    >
                      {GenderTypeEnum[2].name}
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('forms.promoter.cellphone.label')}
                    value={form && form.cellphone}
                    onChange={(val) => {
                      this.fieldChange('cellphone', val);
                    }}
                    mask="(99) 99999-9999"
                    type="tel"
                  />
                </Col>
              </Row>

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.promoter.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                      maxLength={80}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.promoter.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                      maxLength={80}
                    />
                  </Col>
                </Row>
              )}

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={6}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.administratorDetails.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('forms.goBackButtonText')}
                    href={I18n.t('routes.panel.administrators.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('forms.submitButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  promoterDetails: PromoterSelectors.getPromoterDetails(state),
  userDetails: UserSelectors.getUserDetails(state),
});

// const mapDispatchToProps = (dispatch) => ({

// });

export default connect(
  mapStateToProps,
  null,
)(
  PromoterForm,
);
