import CategoryRequests from '../../api/category';
import HandleErrors from '../../services/handleErrors';

export const ACTION_SAVE_CATEGORY_SELECTIVES = 'ACTION_SAVE_CATEGORY_SELECTIVES';

export const getCategorySelectives = () => async (dispatch) => {
  try {
    const categoryDetails = await CategoryRequests.getCategorySelectives();
    dispatch({
      type: ACTION_SAVE_CATEGORY_SELECTIVES,
      payload: categoryDetails,
    });
  } catch (err) {
    HandleErrors(err);
  }
};
