import OrganizerRequests from '../../api/organizer';
import HandleErrors from '../../services/handleErrors';

import {
  addLoading,
  removeLoading,
} from './loading';

export const ACTION_SAVE_ORGANIZERS_PAGINATED = 'ACTION_SAVE_ORGANIZERS_PAGINATED';
export const ACTION_SAVE_ORGANIZER_DETAILS = 'ACTION_SAVE_ORGANIZER_DETAILS';
export const ACTION_SAVE_ORGANIZERS = 'ACTION_SAVE_ORGANIZERS';

export const getOrganizerDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const details = await OrganizerRequests.getDetails(id);
    dispatch({
      type: ACTION_SAVE_ORGANIZER_DETAILS,
      payload: details,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOrganizers = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const organizers = await OrganizerRequests.getAll();

    dispatch({
      type: ACTION_SAVE_ORGANIZERS,
      payload: organizers.rows,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getOrganizersPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const organizersPaginated = await OrganizerRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_ORGANIZERS_PAGINATED,
      payload: organizersPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createOrganizer = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await OrganizerRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateOrganizer = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await OrganizerRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteOrganizer = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await OrganizerRequests.delete(id);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
