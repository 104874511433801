import React from 'react';
import {
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  Menu,
  message,
  Breadcrumb,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  EditOutlined,
  DeleteOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { OrganizerActions } from '../../../app/redux/actions';
import {
  LoadingSelectors, OrganizerSelectors,
} from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { formatCellphone, formatTaxIdentifier } from '../../../app/utils/string';

class Organizers extends React.Component {
  constructor(props) {
    super(props);
    this.dataTableRef = React.createRef();
  }

  actionsDropdown = (id) => (
    <Menu>
      <Menu.Item>
        <Popconfirm
          placement="left"
          title={I18n.t('shared.confirmTitle')}
          onConfirm={() => this.deleteOrganizer(id)}
          okText={I18n.t('shared.yes')}
          cancelText={I18n.t('shared.no')}
        >
          <Button
            type="link"
          >
            {I18n.t('routes.panel.organizers.dataTable.columns.actions.removeText')}
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`${I18n.t('routes.panel.organizersDetails.url')}${id}`}
        >
          <Button
            type="link"
          >
            {I18n.t('routes.panel.organizers.dataTable.columns.actions.goToDetailsText')}
            <EditOutlined />
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  );

  getOrganizers = (params) => {
    const { getOrganizersPaginated } = this.props;

    getOrganizersPaginated(params);
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  deleteOrganizer(id) {
    this.props.deleteOrganizer(id, () => {
      message.success(I18n.t('routes.panel.organizers.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const { Content } = Layout;
    const {
      loading,
      organizersPaginated,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')} / {I18n.t('routes.panel.organizers.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="users">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <AuditOutlined stroke="#fc1f55" />
                  </span>
                  {I18n.t('routes.panel.organizers.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administrators.addNewButtonText')}
                  href={`${I18n.t('routes.panel.organizerDetails.url')}add`}
                  icon={<AuditOutlined />}
                />
              </Col>
            </Row>

            <Divider />
            <div>
              <DataTable
                getMethod={(parameters) => this.getOrganizers(parameters)}
                data={organizersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.organizers.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.organizers.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.organizers.dataTable.columns.fantasyName.key'),
                      title: I18n.t('routes.panel.organizers.dataTable.columns.fantasyName.title'),
                      render: (value) => (value ? value.nameFantasy : '--'),
                    },
                    {
                      key: I18n.t('routes.panel.organizers.dataTable.columns.taxIdentifier.key'),
                      title: I18n.t('routes.panel.organizers.dataTable.columns.taxIdentifier.title'),
                      render: (value) => formatTaxIdentifier(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.organizers.dataTable.columns.contact.key'),
                      title: I18n.t('routes.panel.organizers.dataTable.columns.contact.title'),
                      render: (value) => formatCellphone(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.organizers.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Link
                            to={`${I18n.t('routes.panel.organizerDetails.url')}${id}`}
                            style={{ marginRight: '10px' }}
                          >
                            {I18n.t('routes.panel.organizers.dataTable.columns.actions.goToDetailsText')}
                            <EditOutlined />
                          </Link>
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.deleteOrganizer(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Button
                              type="link"
                            >
                              {I18n.t('routes.panel.organizers.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  organizersPaginated: OrganizerSelectors.getOrganizersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizersPaginated: (parameters) => dispatch(OrganizerActions.getOrganizersPaginated(parameters)),
  deleteOrganizer: (id, callback) => dispatch(OrganizerActions.deleteOrganizer(id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Organizers,
);
