import React from "react";
import { Layout, Row, Col, Breadcrumb, Icon, Divider, Collapse, Popconfirm, message } from "antd";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { ShoppingCartOutlined, AuditOutlined, SearchOutlined, RetweetOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { Link } from "@reach/router";

import DataTable from "../../../components/shared/DataTable/DataTable";
import AdvancedButton from "../../../components/shared/AdvancedButton/AdvancedButton";
import AdvancedInput from "../../../components/shared/AdvancedInput/AdvancedInput";
import AdvancedRangePickerWithEnd from "../../../components/shared/AdvancedRangePickerWithEnd/AdvancedRangePickerWithEnd";
import { EventActions } from "../../../app/redux/actions";
import { LoadingSelectors, EventSelectors } from "../../../app/redux/reducers";
import { toCurrencyLocale } from "../../../app/utils/currency";
import { PaymentStatus, PaymentStatusSelector, TicketTypeEnum } from "../../../app/enum/paymentStatus";
import AdvancedSelect from "../../../components/shared/AdvancedSelect/AdvancedSelect";

const { Panel } = Collapse;

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        params: {
          eventTitle: null,
          eventType: null,
          dateEventInitial: null,
          isActive: null,
        },
    };
    this.dataTableRef = React.createRef();
    this.radioRef = React.createRef();
  }

  componentDidMount() {
    const { id, getEventDetails } = this.props;
    if (id) getEventDetails({ eventId: id });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getEvents(params);
  };

  cleanAdvancedFilters = () => {
    this.setState({ params: {} });
    this.getEvents({});
  };

  getEvents = (params) => {
    const { id, getEventSales } = this.props;

    if (id) {
      params = {
        ...params,
        eventId: id,
      };
    }
    getEventSales({
      ...params,
      ...this.state.params,
    });
  };

  onRefund = (record) => {
    const { setRefund } = this.props;
    setRefund({ id: record.id, amount: record.totalAmount }, () => {
      message.success(I18n.t("routes.panel.purchases.successRefund"));
      this.dataTableRef.current.reset();
    });
  };

  handlePaymentStatus = (record) => {
    const options = TicketTypeEnum[record.ticket[0].status]?.name;

    switch (options) {
      case "Criar":
        return "criar";
      case "Recusado":
        return "recusado";
      case "Criado":
        return "criado";
      case "Validado":
        return "validado";
      case "Capturado":
        return "capturado";
      case "Cancelado":
        return "cancelado";
      case "Reembolso parcial":
        return "reembolso parcial";
      case "Reembolçado":
        return "reembolçado";
      case "Pago":
        return "pago";
      case "Expirado":
        return "expirado";
      case "Pendente":
        return "pendente";
      case "Parcialmente pago":
        return "parcialmente pago";
      case "Extornado":
        return "extornado";
      case "Bloqueado":
        return "bloqueado";
      case "Aprovado":
        return "aprovado";
      default:
        break;
    }
  };

  handlePaymentWay = (record) => {
    const options = record.type;

    switch (options) {
      case "credit":
        return "credito";
      case "bank_slip":
        return "boleto";
      case "pix":
        return "pix";
      default:
        break;
    }
  };

  getTableColumns() {
    const { id } = this.props;

    const columns = [
      {
        key: I18n.t("routes.panel.purchases.dataTable.columns.userName.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.userName.title"),
        render: (value) => (value && value.name) || "--",
      },
      {
        key: I18n.t("routes.panel.purchases.dataTable.columns.paymentWay.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.paymentWay.title"),
        render: (value, record) =>
          (record && `${this.handlePaymentWay(record)} / ${this.handlePaymentStatus(record)}`) || "--",
      },
      {
        key: I18n.t("routes.panel.purchases.dataTable.columns.ticket.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.ticket.title"),
        render: (value) => this.getTickets(value) || "--",
      },
      {
        key: I18n.t("routes.panel.purchases.dataTable.columns.totalAmount.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.totalAmount.title"),
        render: (value) => toCurrencyLocale(value) || "--",
      },
      {
        key: I18n.t("routes.panel.purchases.dataTable.columns.createdAt.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.createdAt.title"),
        render: (value, record) =>
          (record && record.ticket[0] && moment(record.ticket[0].createdAt).format("DD/MM/YYYY [-] HH:mm")) || "--",
      },
    ];

    if (id) {
      columns.splice(1, 0, {
        key: I18n.t("routes.panel.purchases.dataTable.columns.userEmail.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.userEmail.title"),
        render: (value, record) => (record && record.user && record.user.email) || "--",
      });
    }

    if (!id) {
      columns.splice(0, 0, {
        key: I18n.t("routes.panel.purchases.dataTable.columns.eventName.key"),
        title: I18n.t("routes.panel.purchases.dataTable.columns.eventName.title"),
        render: (value) => (value && value.title) || "--",
      });
      columns.push({
        key: I18n.t("routes.panel.purchases.dataTable.columns.actions.key"),
        title: "",
        render: (value, record) => (
          <>
            <div className="dataTable__item--right">
              <Link to={`${I18n.t("routes.panel.purchases.url")}${value}`} style={{ marginRight: "10px" }}>
                {I18n.t("routes.panel.purchases.dataTable.columns.actions.goToDetailsSimpleText")}
                <ShoppingCartOutlined />
              </Link>
            </div>
            <div className="dataTable__item--right">
              {record.status === PaymentStatus.FULLY_REFUNDED ? (
                <span className="purchaseDetails__fully-refunded">
                  {I18n.t("routes.panel.purchases.dataTable.columns.actions.refund.fullyRefundedText")}
                </span>
              ) : (
                <Popconfirm
                  title={I18n.t("routes.panel.purchases.dataTable.columns.actions.refund.popUp.title")}
                  okText={I18n.t("shared.yes")}
                  cancelText={I18n.t("shared.no")}
                  placement="left"
                  onConfirm={() => this.onRefund(record)}
                >
                  <span type="button" color="transparent" className="purchaseDetails__refund">
                    {I18n.t("routes.panel.purchases.dataTable.columns.actions.refund.refundText")}
                    <RetweetOutlined style={{ marginLeft: "2px" }} />
                  </span>
                </Popconfirm>
              )}
            </div>
          </>
        ),
      });
    }

    return columns;
  }

  // eslint-disable-next-line class-methods-use-this
  getTickets(tickets) {
    if (!tickets || tickets.length <= 0) return "--";
    return (
      <div>
        {tickets.map((ticket) => (
          <p key={ticket.id} className="tickets">
            {ticket.ticketsTypes && ticket.ticketsTypes.type} -{" "}
            {ticket.ticketsTypes && toCurrencyLocale(ticket.ticketsTypes.price)}
          </p>
        ))}
      </div>
    );
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    if (name === "createdAt") {
      params[name] = value || false;
    } else {
      params[name] = value || "";
    }
    this.setState({ params });
  };

  render() {
    const { Content } = Layout;
    const { eventSales, loading, eventDetails, id } = this.props;

    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t("routes.panel.pageTitle")}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t("routes.panel.purchases.url")}>
                <Icon type="lock" /> <span>{I18n.t("routes.panel.purchases.pageTitle")}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter" expandIconPosition="right">
            <Panel header={I18n.t("routes.panel.events.advancedFilters.title")} key="1">
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.eventTitle}
                      onChange={(val) => this.fieldChange("eventTitle", val)}
                      placeholder={I18n.t("routes.panel.events.advancedFilters.eventName")}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.userName}
                      onChange={(val) => this.fieldChange("userName", val)}
                      placeholder={I18n.t("routes.panel.events.advancedFilters.userName")}
                    />
                  </Col>

                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.userEmail}
                      onChange={(val) => this.fieldChange("userEmail", val)}
                      placeholder={I18n.t("routes.panel.events.advancedFilters.emailUser")}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedRangePickerWithEnd
                      placeholder={I18n.t("routes.panel.events.advancedFilters.purchaseDate")}
                      format={I18n.t("routes.panel.events.advancedFilters.shared.formatDate")}
                      onChange={(val) => this.fieldChange("createdAt", val)}
                      value={params && params.createdAt}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      defaultValue={params && params.status}
                      value={params && params.status}
                      onChange={(val) => this.fieldChange("status", Number(val))}
                      options={PaymentStatusSelector}
                      placeholder={I18n.t("routes.panel.purchases.dataTable.columns.status.title")}
                      labelStyle={{ paddingBottom: 5 }}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24} className="advanced-filter__search-button text-right">
                    <AdvancedButton
                      type="link"
                      text={I18n.t("routes.panel.events.advancedFilters.clearButtonText")}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t("routes.panel.events.advancedFilters.filterButtonText")}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="users">
            <Row>
              <Col span={20}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <ShoppingCartOutlined stroke="#fc1f55" />
                  </span>
                  {I18n.t("routes.panel.purchases.pageTitle")} {eventDetails && id && `- ${eventDetails.title}`}
                </h2>
              </Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {id && eventDetails && (
                  <AdvancedButton
                    text={I18n.t("routes.panel.purchases.goToEvent")}
                    href={`${I18n.t("routes.panel.eventDetails.url")}${eventDetails.id}`}
                    icon={<AuditOutlined />}
                  />
                )}
              </Col>
            </Row>
            <Divider />
            {id && eventDetails && (
              <>
                <Row>
                  <Col span={5}>
                    <span className="tickets-info">
                      <strong>{I18n.t("routes.panel.purchases.advancedFilters.totalQuantitySold")}: </strong>
                      {eventDetails.totalQuantitySold || "--"}
                    </span>
                  </Col>
                  <Col span={5}>
                    <span className="tickets-info">
                      <strong>{I18n.t("routes.panel.purchases.advancedFilters.remainingTicket")}: </strong>
                      {eventDetails.remainingTicket || "--"}
                    </span>
                  </Col>
                  <Col span={5}>
                    <span className="tickets-info">
                      <strong>{I18n.t("routes.panel.purchases.advancedFilters.totalRevenues")}: </strong>
                      {toCurrencyLocale(eventDetails.totalRevenues) || "--"}
                    </span>
                  </Col>
                </Row>
                <Divider />
              </>
            )}
            <div>
              <DataTable
                getMethod={(parameters) => this.getEvents(parameters)}
                data={eventSales}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={this.getTableColumns()}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <span className="purchaseDetails__refund-message">{I18n.t("routes.panel.purchases.refundInfo")}</span>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventsPaginated: EventSelectors.getEventsPaginated(state),
  eventSales: EventSelectors.getEventSales(state),
  eventDetails: EventSelectors.getEventDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEventSales: (parameters) => dispatch(EventActions.getEventSales(parameters)),
  getEventDetails: (id) => dispatch(EventActions.getEventSalesDetails(id)),
  getEventsPaginated: (parameters) => dispatch(EventActions.getEventsPaginated(parameters)),
  setRefund: (parameters, callback) => dispatch(EventActions.setRefund(parameters, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
