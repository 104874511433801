import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_COUPONS_PAGINATED
} from '../actions/coupons';

const initialState = Immutable({
  couponsPaginated: null,
});

export default function coupon(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_COUPONS_PAGINATED:
      state = {
        ...state,
        couponsPaginated: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getCouponsPaginated(state) {
  return state.coupon.couponsPaginated;
}
