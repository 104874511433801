const EventType = {
  HIPHOP: 1,
  BALADA: 2,
  ELECTRONICS: 3,
  POP: 4,
  UNIVERSITY: 5,
  RODEO: 6,
  PAGODA: 7,
  SERTANEJO: 8,
  ROCK: 9,
  SUNSET: 10,
  BAR: 11,
  SHOWS: 12,
  FUNK: 13,
  STANDUP: 14,
  THEMATIC: 15,
  LGBT: 16,
};

export const EventTypes = [[EventType.HIPHOP, 'Hip Hop'],
  [EventType.BALADA, 'Baladas'],
  [EventType.ELECTRONICS, 'Eletrônica'],
  [EventType.POP, 'Pop'],
  [EventType.UNIVERSITY, 'Universitárias'],
  [EventType.RODEO, 'Rodeio'],
  [EventType.PAGODA, 'Pagode'],
  [EventType.SERTANEJO, 'Sertanejo'],
  [EventType.ROCK, 'Rock'],
  [EventType.SUNSET, 'Sunset'],
  [EventType.BAR, 'Bar'],
  [EventType.SHOWS, 'Shows'],
  [EventType.FUNK, 'Funk'],
  [EventType.STANDUP, 'Stand Up'],
  [EventType.THEMATIC, 'Temática'],
  [EventType.LGBT, 'LGBT']];

export const eventTypeMap = new Map(EventTypes);

export default EventType;
