import Config from "../config/api.config";

function getBaseUrl(isAuth) {
  const { hostname } = window.location;
  let apiUrl = null;
  if (isAuth) apiUrl = Config.auth.path;
  else {
    switch (hostname) {
      case "localhost":
      case "www.localhost":
      case "127.0.0.1":
      case "dashboard-dev.luppi.app":
        apiUrl = Config.development.path;
        break;
      case "dashboard-hom.luppi.app":
        apiUrl = Config.homolog.path;
        break;
      case "dashboard.luppi.app":
        apiUrl = Config.production.path;
        break;
      default:
        apiUrl = null;
    }
  }

  return apiUrl;
}

function getApyKey(isAuth) {
  const { hostname } = window.location;
  let apiKey;
  if (!isAuth) apiKey = undefined;
  else {
    switch (hostname) {
      case "localhost":
      case "dashboard-dev.luppi.app":
        apiKey = Config.development.apiKey;
        break;
      case "dashboard-hom.luppi.app":
        apiKey = Config.homolog.apiKey;
        break;
      case "dashboard.luppi.app":
        apiKey = Config.production.apiKey;
        break;
      default:
        apiKey = undefined;
    }
  }

  return apiKey;
}

export function handleAxiosError(err) {
  if (err.response && err.response.status === 400 && err.response.data && err.response.data.error) {
    throw new Error(err.response.data.error);
  }
  if (err.response && err.response.status === 401) {
    // throw new Error(CodeErrors.INVALID_TOKEN);
  }
  const error = {
    url: err.config && err.config.url,
    method: err.config && err.config.method,
    headers: err.config && err.config.headers,
    data: err.config && err.config.data,
    response: null,
    status: null,
    message: err.message,
  };
  if (err.response) {
    error.response = err.response.data;
    error.status = err.response.status;
  }
  return error;
}

export default {
  getBaseUrl,
  getApyKey,
};
