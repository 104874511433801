import React from 'react';
import {
  Input,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import CurrencyInput from 'react-currency-input';

const AdvancedInput = (
  {
    label,
    placeholder,
    onChange,
    type,
    value,
    disabled,
    isPassword,
    isMoney,
    precision,
    isTextArea,
    maxLength,
    hasCountDown,
    onPressEnter,
  },
) => (
  <div className="advanced-input">
    <label className="advanced-input__label">
      <span className="advanced-input__label__inner">{label}</span>
      {
        isPassword && (
          <Input.Password
            placeholder={placeholder || I18n.t('shared.type')}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value || undefined}
            disabled={disabled || false}
          />
        )
      }
      {
        isMoney && (
          <CurrencyInput
            className="ant-input"
            prefix={`${I18n.t('shared.moneyPrefix')} `}
            decimalSeparator={`${I18n.t('shared.decimalSeparator')}`}
            thousandSeparator={`${I18n.t('shared.thousandSeparator')}`}
            value={value || undefined}
            onChangeEvent={(_ev, _, floatValue) => onChange(floatValue)}
            inputType="tel"
            disabled={disabled || false}
            precision={precision || 2}
          />
        )
      }

      {
        isTextArea && (
          <Input.TextArea
            placeholder={placeholder || I18n.t('shared.type')}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            value={value}
            disabled={disabled || false}
            allowClear
            autoSize={{
              minRows: 3,
              maxRows: 6,
            }}
            maxLength={maxLength || 8000}
          />
        )
      }

      {
        !isPassword && !isMoney && !isTextArea && (
          <Input
            placeholder={placeholder || I18n.t('shared.type')}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            allowClear
            maxLength={maxLength || 80}
            onPressEnter={(e) => !!onPressEnter && onPressEnter(e.currentTarget.value)}
          />
        )
      }
      {isTextArea && hasCountDown && (
        <span className="advanced-input__countDown">{8000 - (value && value.length)}</span>
      )}
    </label>
  </div>
);

export default AdvancedInput;
