import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_EVENTS_PAGINATED,
  ACTION_SAVE_EVENT_DETAILS,
  ACTION_SAVE_EVENT_TYPES,
  ACTION_SAVE_EVENT_SALES,
} from '../actions/event';

const initialState = Immutable({
  eventsPaginated: null,
  eventDetails: null,
  eventSales: null,
  eventTypes: null,
});

export default function event(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_EVENTS_PAGINATED:
      state = {
        ...state,
        eventsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_EVENT_DETAILS:
      state = {
        ...state,
        eventDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_EVENT_TYPES:
      state = {
        ...state,
        eventTypes: action.payload,
      };
      return state;
    case ACTION_SAVE_EVENT_SALES:
      state = {
        ...state,
        eventSales: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getEventsPaginated(state) {
  return state.event.eventsPaginated;
}

export function getEventDetails(state) {
  return state.event.eventDetails;
}

export function getEventTypes(state) {
  return state.event.eventTypes;
}

export function getEventSales(state) {
  return state.event.eventSales;
}
