import {
  addLoading,
  removeLoading,
} from './loading';
import UserRequests from '../../api/user';
import { ProfileType } from '../../enum/profileType';
import HandleErrors from '../../services/handleErrors';

export const ACTION_SAVE_USERS_PAGINATED = 'ACTION_SAVE_USERS_PAGINATED';
export const ACTION_SAVE_USER_DETAILS = 'ACTION_SAVE_USER_DETAILS';
export const ACTION_SAVE_USER_KANBAN = 'ACTION_SAVE_USER_KANBAN';
export const ACTION_SAVE_USER_SELECTIVES = 'ACTION_SAVE_USER_SELECTIVES';

export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const getUsersPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: parameters.pageSize || 10,
    offset: parameters.offset || 0,
    orderBy: 'createdAt',
    desc: true,
  };

  try {
    const usersPaginated = await UserRequests.getUsersPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await UserRequests.getUserDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_USER_DETAILS,
      payload: data,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersKanban = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const users = await UserRequests.getUsersKanban();
    dispatch({
      type: ACTION_SAVE_USER_KANBAN,
      payload: users,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserSelectives = (me, searchInput) => async (dispatch) => {
  try {
    let params = {
      searchInput,
    };

    if (me && me.profileType !== ProfileType.ADMIN) {
      params = {
        ...params,
      };
    }
    const userDetails = await UserRequests.getUserSelectives(params);
    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES,
      payload: userDetails,
    });
  } catch (err) {
    HandleErrors(err);
  }
};

export const updateUser = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      userId: id,
    };

    await UserRequests.update(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createUserDaily = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.USER,
    };
    await UserRequests.createDaily(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createAdmin = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.ADMIN,
      genderInterest: 1,
      maritalStatus: 1,
      matchEnabled: true,
      description: 'Administrador',
    };
    await UserRequests.createUser(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const removeUser = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.delete(id);
    callback();
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
