import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProfileType = {
  ADMIN: 1,
  USER: 2,
  PROMOTER: 3,
  ORGANIZER: 4,
};

export const ProfileTypeArray = [
  { id: ProfileType.ADMIN, name: <Translate value="enum.profileType.admin" /> },
  { id: ProfileType.USER, name: <Translate value="enum.profileType.user" /> },
  { id: ProfileType.ORGANIZER, name: <Translate value="enum.profileType.operator" /> },
  { id: ProfileType.PROMOTER, name: <Translate value="enum.profileType.promoter" /> },
];

export const GetProfileTypeTitle = (value) => ProfileTypeArray.find((o) => o.id === value).name;
