import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_CATEGORY_SELECTIVES,
} from '../actions/category';

const initialState = Immutable({
  categorySelectives: null,
});

export default function category(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_CATEGORY_SELECTIVES:
      state = {
        ...state,
        categorySelectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getCategorySelectives(state) {
  return state.category.categorySelectives;
}
