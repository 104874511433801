export const GenderType = {
  MALE: 1,
  FEMALE: 2,
  UNDETERMINED: 3,
};

export const GenderTypeEnum = {
  1: { id: GenderType.MALE, name: 'Masculino' },
  2: { id: GenderType.FEMALE, name: 'Feminino' },
  3: { id: GenderType.UNDETERMINED, name: 'Indeterminado'},
};
