import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_USERS_PAGINATED,
  ACTION_SAVE_USER_DETAILS,
  ACTION_SAVE_USER_KANBAN,
  ACTION_SAVE_USER_SELECTIVES,
} from '../actions/user';

const initialState = Immutable({
  usersPaginated: null,
  userDetails: null,
  userSelectives: null,
  usersKanban: null,
});

export default function user(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_USERS_PAGINATED:
      state = {
        ...state,
        usersPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_USER_DETAILS:
      state = {
        ...state,
        userDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_USER_KANBAN:
      state = {
        ...state,
        usersKanban: action.payload,
      };
      return state;
    case ACTION_SAVE_USER_SELECTIVES:
      state = {
        ...state,
        userSelectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getUsersPaginated(state) {
  return state.user.usersPaginated;
}

export function getUserDetails(state) {
  return state.user.userDetails;
}

export function getUsersKanban(state) {
  return state.user.usersKanban;
}

export function getUserSelectives(state) {
  return state.user.userSelectives;
}
