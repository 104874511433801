import React from 'react';
import {
  message, Divider,
  Layout, Checkbox,
} from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import DataTable from '../../../components/shared/DataTable';
import { LoadingSelectors, PushNotificationSelectors } from '../../../app/redux/reducers';
import { AuthActions, PushNotificationActions } from '../../../app/redux/actions';
import * as DateUtils from '../../../app/utils/date';
import AdvancedButton from '../../../components/shared/AdvancedButton';

class PushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.dataTableRef = React.createRef();
    this.state = {
      form: {
        type: null,
        title: '',
        description: null,
        hasImage: false,
        image: null,
        selectedUsers: null,
        sendAll: false,
      },
    };
  }

  onInputChange(name, value) {
    const { form } = this.state;
    form[name] = value ? value.toString() : null;
    this.setState({ form });
  }

  onSelectRows(rows) {
    if (rows && rows.length >= 1) this.setState({ selectedUsers: rows });
  }

  async onSubmit(e) {
    e.preventDefault();
    const { form, selectedUsers } = this.state;
    try {
      const { createNotification } = this.props;

      // eslint-disable-next-line max-len
      if (!form.sendAll && (!selectedUsers || selectedUsers.length <= 0)) return message.error(I18n.t('pushNotification.errors.empty'));
      if (!form.title || !form.description) return message.error(I18n.t('pushNotification.errors.generic'));

      const obj = {
        title: form && form.title,
        body: form && form.description,
        type: 1,
      };

      if (!form.sendAll) obj.targetIds = selectedUsers && selectedUsers.map((i) => i.id);

      await createNotification(obj, () => this.onSendNotificationSuccess());
    } catch (error) {
      message.error(I18n.t('pushNotification.errors.undefined'));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSendNotificationSuccess() {
    message.success(I18n.t('pushNotification.success'));
    setTimeout(() => window.location.reload(false), 2000);
  }

  // eslint-disable-next-line class-methods-use-this
  getColumns(params) {
    return params.map((o) => (
      {
        title: o.title,
        dataIndex: o.key,
        key: o.key,
        render: o.render ? o.render : undefined,
      }
    ));
  }

  render() {
    const { Content } = Layout;
    const { form } = this.state;
    const { getUsersForPushNotificationPaginated, usersForPushNotificationPaginated, loading } = this.props;

    return (
      <>
        <Content className="panel__layout__title">
          {I18n.t('pushNotification.pageTitle')}
        </Content>
        <form
          className="push-notification__form"
          onSubmit={(ev) => this.onSubmit(ev)}
        >
          <div className="row">
            <div className="col-lg-8">
              <Content className="panel__layout__content mr-0">
                <div className="push-notification">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="push-notification__block-title">
                        {I18n.t('pushNotification.form.blockTitle')}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <AdvancedInput
                        label={I18n.t('pushNotification.form.title.label')}
                        onChange={(value) => this.onInputChange('title', value)}
                        value={form && form.title}
                        placeholder={I18n.t('shared.type')}
                        maxLength={80}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <AdvancedInput
                        label={I18n.t('pushNotification.form.description.label')}
                        onChange={(value) => this.onInputChange('description', value)}
                        value={form && form.description}
                        isTextarea
                        placeholder={I18n.t('shared.type')}
                        maxLength={512}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Checkbox onChange={(ev) => this.onInputChange('sendAll', ev.target.checked)}>
                        {I18n.t('pushNotification.form.sendAll.label')}
                      </Checkbox>
                    </div>
                  </div>

                  {/* {form && form.hasImage && !form.image && (
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-12">
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              {I18n.t('pushNotification.form.image.dragger.title')}
                            </p>
                            <p className="ant-upload-hint">
                              {I18n.t('pushNotification.form.image.dragger.tip')}
                            </p>
                          </Dragger>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {form && form.hasImage && form.image && (
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="push-notification__form__image-thumb"
                            style={{ backgroundImage: `url(${form.image})` }}
                          >
                            <button
                              className="push-notification__form__image-thumb__remove-button"
                              type="button"
                              onClick={() => this.onInputChange('image', null)}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Content>

              {form && !form.sendAll && (
              <Content className="panel__layout__content mr-0">
                <div className="push-notification">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="push-notification__block-title">
                        {I18n.t('pushNotification.form.datatable.title')}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DataTable
                        getMethod={(parameters) => getUsersForPushNotificationPaginated(parameters)}
                        data={usersForPushNotificationPaginated}
                        loading={loading > 0}
                        ref={this.dataTableRef}
                        columns={this.getColumns(
                          [
                            {
                              key: I18n.t('pushNotification.form.datatable.name.key'),
                              title: I18n.t('pushNotification.form.datatable.name.label'),
                              render: (value) => value,
                            },
                            {
                              key: I18n.t('pushNotification.form.datatable.email.key'),
                              title: I18n.t('pushNotification.form.datatable.email.label'),
                              render: (value) => value,
                            },
                            {
                              key: I18n.t('pushNotification.form.datatable.cellphone.key'),
                              title: I18n.t('pushNotification.form.datatable.cellphone.label'),
                              render: (value) => value || '--',
                            },
                            {
                              key: I18n.t('pushNotification.form.datatable.createdAt.key'),
                              title: I18n.t('pushNotification.form.datatable.createdAt.label'),
                              render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                            },
                          ],
                        )}
                        bordered={false}
                        pagination={false}
                        size="middle"
                        locale={{
                          emptyText: I18n.t('shared.nothingToShow'),
                        }}
                        selectable
                        onSelectRow={(rows) => this.onSelectRows(rows)}
                      />
                    </div>
                  </div>
                </div>
              </Content>
              )}

              <Content className="panel__layout__content mr-0">
                <div className="row">
                  <div
                    className="col-12 text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('forms.goBackButtonText')}
                      href={I18n.t('routes.panel.users.url')}
                    />
                    <Divider
                      className="form-button-divider"
                      type="vertical"
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.save')}
                      icon={<SaveOutlined />}
                      loading={loading > 0}
                    />
                  </div>
                </div>
              </Content>
            </div>
            <div className="col-lg-4">
              <div className="push-notification__preview">
                <div className="push-notification__preview__content">
                  <div className="row">
                    <div className="col-2">
                      <div
                        className="push-notification__preview__content__photo"
                        style={
                          {
                            backgroundImage: `url(${form && form.image && form.hasImage
                              ? form.image
                              : '/assets/img/img_placeholder_push.png'})`,
                          }
                        }
                      />
                    </div>
                    <div className="col-8">
                      <div className="push-notification__preview__content__title">
                        {form && form.title ? form.title : I18n.t('pushNotification.preview.titlePlaceHolder')}
                      </div>
                      <div className="push-notification__preview__content__description">
                        {form && form.description
                          ? form.description
                          : I18n.t('pushNotification.preview.descriptionPlaceHolder')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersForPushNotificationPaginated: PushNotificationSelectors.getUsersForPushNotificationPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersForPushNotificationPaginated: (parameters, me) => dispatch(
    PushNotificationActions.getUsersForPushNotification(parameters, me),
  ),
  getMe: () => dispatch(AuthActions.getMe()),
  createNotification: (params, callback) => dispatch(PushNotificationActions.createNotification(params, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotification);
