import {
  addLoading,
  removeLoading,
} from './loading';
import CouponRequests from '../../api/coupons';
import HandleErrors from '../../services/handleErrors';

export const ACTION_SAVE_COUPONS_PAGINATED = 'ACTION_SAVE_COUPONS_PAGINATED';

export const getCouponsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());

  const params = {
    ...parameters,
    isDESC: true
  }

  try {
    const couponsPaginated = await CouponRequests.getCouponsPaginated(params);
    dispatch({
      type: ACTION_SAVE_COUPONS_PAGINATED,
      payload: couponsPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createCoupon = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const couponCreated = await CouponRequests.createCoupon(data);
    if (callback) callback(couponCreated)
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCouponById = (couponId, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CouponRequests.deleteCouponById(couponId);
    if (callback) callback()
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const findCouponDetailByIdAndReturn = (couponId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return await CouponRequests.findCouponById(couponId);
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const editCoupon = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CouponRequests.editCoupon(id, data);
    if(callback) callback()
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
