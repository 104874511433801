import React from 'react';
import {
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  Menu,
  message,
  Collapse,
  Breadcrumb,
  Icon,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import moment from 'moment-timezone';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { AuthActions, CouponActions } from '../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, CouponSelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { CouponDiscounType } from '../../../app/enum/couponsType';

const { Panel } = Collapse;

class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        title: ''
      },
    };
    this.dataTableRef = React.createRef();
  }

  getCoupons = (params) => {
    const { getCouponsPaginated } = this.props;

    this.setState({ params });
    getCouponsPaginated(params);
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getCoupons(params);
  };

  cleanAdvancedFilters = () => this.getCoupons();

  getDiscountType(type) {
    switch (type) {
      case CouponDiscounType.PERCENTAGE:
        return I18n.t('routes.panel.discountCoupon.dataTable.columns.couponType.percentageType')
      case CouponDiscounType.VALUE:
        return I18n.t('routes.panel.discountCoupon.dataTable.columns.couponType.valueType')
      default:
        return '---'
    }
  }

  async deleteCoupon(couponId) {
    await this.props.deleteCouponById(couponId, () => {
      message.success(I18n.t('routes.panel.discountCoupon.messages.deleteSuccess'))
      this.getCoupons(this.state.params)
    })
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      couponsPaginated,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')} / {I18n.t('routes.panel.discountCoupon.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
            expandIconPosition="right"
          >
            <Panel
              header={I18n.t('routes.panel.users.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('forms.coupon.codeFilter.label')}
                      maxLength={80}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('routes.panel.users.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('routes.panel.users.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="users">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <TagOutlined />
                  </span>
                  {I18n.t('routes.panel.discountCoupon.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administrators.addNewButtonText')}
                  href={`${I18n.t("routes.panel.discountCoupon.url")}/add`}
                  icon={<TagOutlined />}
                />
              </Col>
            </Row>

            <Divider />
            <div>
              <DataTable
                getMethod={(parameters) => this.getCoupons(parameters)}
                data={couponsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.code.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.code.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.event.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.event.title'),
                      render: (value) => value?.title || '--',
                    },
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.value.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.value.title'),
                      render: (value, row) => {
                        if(!value) return '--'
                        if (row.discountType === CouponDiscounType.VALUE){
                          return Number(value).toLocaleString('pt-BR' ,{style: 'currency', currency: 'BRL'});
                        }
                        return Number(value/100).toLocaleString('pt-BR' ,{style: 'percent', minimumFractionDigits:  2})
                      }
                    },
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.couponType.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.couponType.title'),
                      render: (value) => this.getDiscountType(value),
                    },
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.startAt.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.startAt.title'),
                      render: (value) => value ? moment(value).add(1, 'day').format('DD/MM/YYYY') : '--',
                    },
                    {
                      key: I18n.t('routes.panel.discountCoupon.dataTable.columns.endAt.key'),
                      title: I18n.t('routes.panel.discountCoupon.dataTable.columns.endAt.title'),
                      render: (value) => value ? moment(value).add(1, 'day').format('DD/MM/YYYY') : '--',
                    },
                    {
                      key: I18n.t('routes.panel.users.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Link
                            to={`${I18n.t('routes.panel.discountCoupon.url')}/${id}`}
                            style={{ marginRight: '10px' }}
                          >
                            {I18n.t('routes.panel.organizers.dataTable.columns.actions.goToDetailsText')}
                            <EditOutlined />
                          </Link>
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.deleteCoupon(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Button
                              type="link"
                            >
                              {I18n.t('routes.panel.organizers.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  couponsPaginated: CouponSelectors.getCouponsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCouponsPaginated: (parameters) => dispatch(CouponActions.getCouponsPaginated(parameters)),
  deleteCouponById: (couponId, callback) => dispatch(CouponActions.deleteCouponById(couponId, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Coupons,
);
