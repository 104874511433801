import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import UtilsRequests from '../../api/utils';
import HandleErrors from '../../services/handleErrors';

export const getAddressByCep = (cep) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const { data } = await UtilsRequests.getAddressByCep(cep);
    return data;
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadBase64Image = (payload, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoading());
  try {
    response = await UtilsRequests.uploadBase64Image(payload);
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }

  if (callback && typeof callback === 'function') {
    callback(response);
  }
};
