import { Translate } from 'react-redux-i18n';
import React from 'react';

const AgencyType = {
  BNP_PARIBAS_BRASIL: 'BNP Paribas Brasil',
  BRB: 'BRB',
  BS2: 'BS2',
  BANCO_C6: 'Banco C6',
  BANCO_DAYCOVAL: 'Banco Daycoval',
  BANCO_ORIGINAL: 'Banco Original',
  BANCO_TOPAZIO: 'Banco Topazio',
  BANCO_DA_AMAZONIA: 'Banco da Amazonia',
  BANCO_DO_BRASIL: 'Banco do Brasil',
  BANCO_DO_NORDESTE: 'Banco do Nordeste',
  BANESTES: 'Banestes',
  BANPARA: 'Banpará',
  BANRISUL: 'Banrisul',
  BRADESCO: 'Bradesco',
  CAIXA_ECONOMICA: 'Caixa Econômica',
  CITIBANK: 'Citibank',
  COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO: 'Cooperativa Central de Credito Noroeste Brasileiro',
  CORA: 'Cora',
  GERENCIANET_PAGAMENTOS_DO_BRASIL: 'Gerencianet Pagamentos do Brasil',
  GLOBAL_SCM: 'Global SCM',
  INTER: 'Inter',
  ITAU: 'Itaú',
  JP_MORGAN: 'JP Morgan',
  JUNO: 'Juno',
  MERCADO_PAGO: 'Mercado Pago',
  MERCANTIL_DO_BRASIL: 'Mercantil do Brasil',
  MODAL: 'Modal',
  MONEY_PLUS: 'Money Plus',
  NEON: 'Neon',
  NEXT: 'Next',
  NUBANK: 'Nubank',
  PJBANK: 'PJBank',
  PAGSEGURO: 'PagSeguro',
  RENDIMENTO: 'Rendimento',
  SAFRA: 'Safra',
  SANTANDER: 'Santander',
  SICOOB: 'Sicoob',
  SICREDI: 'Sicredi',
  STONE: 'Stone',
  UNICRED: 'Unicred',
  UNIPRIME: 'Uniprime',
  UNIPRIME_NORTE_DO_PARANA: 'Uniprime Norte do Paraná',
  VIA_CREDI: 'Via Credi',
  VOTORANTIM: 'Votorantim',
};

export const AgencyTypeArray = [
  {
    id: AgencyType.NEXT,
    name: 'Next',
    agencyMask: '9999-9',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.VOTORANTIM,
    name: 'Votorantim',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.PJBANK,
    name: 'PJBank',
    agencyMask: '9999',
    accountMask: '9999999999-9',
  },
  {
    id: AgencyType.COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO,
    name: 'Cooperativa Central de Credito Noroeste Brasileiro',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.UNIPRIME_NORTE_DO_PARANA,
    name: 'Uniprime Norte do Paraná',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.GLOBAL_SCM,
    name: 'Global SCM',
    agencyMask: '9999',
    accountMask: '99999999999',
  },
  {
    id: AgencyType.CORA,
    name: 'Cora',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.MERCADO_PAGO,
    name: 'Mercado Pago',
    agencyMask: '9999',
    accountMask: '9999999999999-9',
  },
  {
    id: AgencyType.BANCO_DA_AMAZONIA,
    name: 'Banco da Amazonia',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.ITAU,
    name: 'Itaú',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.BRADESCO,
    name: 'Bradesco',
    agencyMask: '9999-9',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.CAIXA_ECONOMICA,
    name: 'Caixa Econômica',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.BANCO_DO_BRASIL,
    name: 'Banco do Brasil',
    agencyMask: '9999-9',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.SANTANDER,
    name: 'Santander',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANRISUL,
    name: 'Banrisul',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.SICREDI,
    name: 'Sicredi',
    agencyMask: '9999',
    accountMask: '9999999',
  },
  {
    id: AgencyType.SICOOB,
    name: 'Sicoob',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.INTER,
    name: 'Inter',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.BRB,
    name: 'BRB',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.VIA_CREDI,
    name: 'Via Credi',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.NEON,
    name: 'Neon',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.NUBANK,
    name: 'Nubank',
    agencyMask: '9999',
    accountMask: '9999999999-9',
  },
  {
    id: AgencyType.PAGSEGURO,
    name: 'Pagseguro',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANCO_ORIGINAL,
    name: 'Banco Original',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.SAFRA,
    name: 'Safra',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MODAL,
    name: 'Modal',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.BANESTES,
    name: 'Banestes',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.UNICRED,
    name: 'Unicred',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MONEY_PLUS,
    name: 'Money Plus',
    agencyMask: '9',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MERCANTIL_DO_BRASIL,
    name: 'Mercantil do Brasil',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.JP_MORGAN,
    name: 'JP Morgan',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.GERENCIANET_PAGAMENTOS_DO_BRASIL,
    name: 'Gerencianet Pagamentos do Brasil',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANCO_C6,
    name: 'Banco C6',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.BS2,
    name: 'BS2',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.BANCO_TOPAZIO,
    name: 'Banco Topazio',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.UNIPRIME,
    name: 'Uniprime',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.STONE,
    name: 'Stone',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.BANCO_DAYCOVAL,
    name: 'Banco Daycoval',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.RENDIMENTO,
    name: 'Rendimento',
    agencyMask: '9999-9',
    accountMask: '9999999999',
  },
  {
    id: AgencyType.BANCO_DO_NORDESTE,
    name: 'Banco do Nordeste',
    agencyMask: '999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.CITIBANK,
    name: 'Citibank',
    agencyMask: '999',
    accountMask: '99999999',
  },
];

export const GetAgencyTypeId = (value) => value && AgencyTypeArray.find((o) => o.name === value).id;
export const GetAgencyTypeName = (value) => value && AgencyTypeArray.find((o) => o.id === value).name;
export const GetAgencyTypeAgencyMask = (value) => {
  const response = value && AgencyTypeArray.find((o) => o.id === value);
  return response && response.agencyMask;
};
export const GetAgencyTypeAccountMask = (value) => {
  const response = value && AgencyTypeArray.find((o) => o.id === value);
  return response && response.accountMask;
};

export const AccountType = {
  NORMAL_SAVINGS: 1,
  CURRENT_ACCOUNTS: 2,
};

export const AccountTypeArray = [
  {
    id: AccountType.NORMAL_SAVINGS,
    name: <Translate value="enum.accountType.normalSavings" />,
  },
  {
    id: AccountType.CURRENT_ACCOUNTS,
    name: <Translate value="enum.accountType.currentAccounts" />,
  },
];

export default AgencyType;
