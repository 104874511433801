import { ProfileType } from '../enum/profileType';
import getInstance from './config';

export default class OrganizerApi {
  static async create(organizerData) {
    const instance = getInstance();
    const { data } = await instance.post('/user/adm', {
      ...organizerData,
      profileType: ProfileType.ORGANIZER,
    });

    return data;
  }

  static async update(id, organizerData) {
    const instance = getInstance();
    const { data } = await instance.put('/user', {
      userId: id,
      ...organizerData,
    });

    return data;
  }

  static async getAll() {
    const instance = getInstance();
    const { data } = await instance.get(`/user/?profileType=${ProfileType.ORGANIZER}&limit=99`);

    return data;
  }

  static async getPaginated(params) {
    const instance = getInstance();
    const { data } = await instance.get(`/user/?profileType=${ProfileType.ORGANIZER}`, {
      params,
    });

    return data;
  }

  static async getDetails(id) {
    const instance = getInstance();
    const { data } = await instance.get(`/user/${id}`);

    return data;
  }

  static async delete(id) {
    const instance = getInstance();
    const { data } = await instance.delete(`/user/${id}`);

    return data;
  }
}
