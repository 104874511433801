import React from 'react';
import {
  Col,
  Layout, message, Row,
  Spin, Breadcrumb, Icon,
} from 'antd';
import { connect } from 'react-redux';
import { navigate, Link } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { EventActions } from '../../../app/redux/actions';
import { LoadingSelectors, EventSelectors } from '../../../app/redux/reducers';
import EventForm from '../../../app/forms/event/EventForm';
import { ViewType } from '../../../app/enum/viewType';

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      eventDetails: null,
    };
  }

  async componentDidMount() {
    const {
      getEventTypes, getEventDetails, id, eventTypes,
    } = this.props;
    if (!id || id === 'add') {
      this.setState({
        viewType: ViewType.ADD,
      });
    } else {
      await getEventDetails(id);
      this.setState({ eventDetails: this.props.eventDetails });
    }
    if (!eventTypes) getEventTypes();
  }

  async updateEvent(id, data) {
    const { updateEventById, createEvent, eventDetails } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await createEvent(data, () => {
          message.success(I18n.t('routes.panel.eventDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.eventDetails.url'));
        });
      } else {
        await updateEventById(eventDetails.id, data, () => {
          message.success(I18n.t('routes.panel.eventDetails.messages.success'));
          navigate(I18n.t('routes.panel.eventDetails.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, eventDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.events.url')}>
                <Icon type="lock" /> <span>{I18n.t('routes.panel.events.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.eventDetails.pageTitleAdd'
                      : 'routes.panel.eventDetails.pageTitle',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="user-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div>
                <Row>
                  <Col span={24}>
                    {(eventDetails || viewType === ViewType.ADD) && (
                      <EventForm
                        data={eventDetails}
                        submitFunction={(userId, data) => this.updateEvent(userId, data)}
                        viewType={viewType}
                        id={id}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventDetails: EventSelectors.getEventDetails(state),
  eventTypes: EventSelectors.getEventTypes(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEventDetails: (id) => dispatch(EventActions.getEventDetails(id)),
  getEventTypes: () => dispatch(EventActions.getEventTypes()),
  updateEventById: (id, data, callback) => dispatch(EventActions.updateEvent(id, data, callback)),
  createEvent: (data, callback) => dispatch(EventActions.createEvent(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  EventDetails,
);
