import React from 'react';
import {
  Radio,
} from 'antd';

const AdvancedRadio = (
  {
    label,
    options,
    optionType,
    onChange,
    value,
    buttonStyle,
    disabled,
  },
) => (
  <div className="advanced-radio">
    <label className="advanced-radio__label">
      <span className="advanced-radio__label__inner">{label}</span>
      <Radio.Group
        options={options}
        onChange={(ev) => onChange(ev.target.value)}
        value={value}
        optionType={optionType || 'button'}
        buttonStyle={buttonStyle || 'outline'}
        disabled={disabled || false}
      />
    </label>
  </div>
);

export default AdvancedRadio;