import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_ORGANIZERS_PAGINATED,
  ACTION_SAVE_ORGANIZERS,
  ACTION_SAVE_ORGANIZER_DETAILS,
} from '../actions/organizer';

const initialState = Immutable({
  organizers: null,
  organizerPaginated: null,
  organizerDetails: null,
});

export default function organizer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_ORGANIZERS:
      state = {
        ...state,
        organizers: action.payload,
      };
      return state;
    case ACTION_SAVE_ORGANIZERS_PAGINATED:
      state = {
        ...state,
        organizersPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_ORGANIZER_DETAILS:
      state = {
        ...state,
        organizerDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getOrganizers(state) {
  return state.organizer.organizers;
}

export function getOrganizersPaginated(state) {
  return state.organizer.organizersPaginated;
}

export function getOrganizerDetails(state) {
  return state.organizer.organizerDetails;
}
