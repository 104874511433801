import getInstance from './config';

export default class CouponApi {
  static async getCouponsPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/coupon', { params });
    return data;
  }

  static async createCoupon(params) {
    const instance = await getInstance();
    const { data } = await instance.post('/coupon', params);
    return data;
  }

  static async deleteCouponById(couponId) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/coupon/${couponId}`);
    return data;
  }

  static async findCouponById(couponId) {
    const instance = await getInstance();
    const { data } = await instance.get(`/coupon/${couponId}`);
    return data;
  }

  static async editCoupon(couponId, couponData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/coupon/${couponId}`, couponData);
    return data;
  }
}
