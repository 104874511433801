import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';

export const defaultValues = {
  pageSize: 10,
  total: 0,
  offset: 0,
  page: 1,
  data: null,
};

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultValues;
    if (!this.props.notGetOnStart) {
      this.props.getMethod(defaultValues);
    }
  }

  componentDidMount() {
    this.setState(defaultValues);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      const { page } = this.state;

      this.setState({
        total: nextProps.data.count,
        page,
      });
    }

    this.setState({ data: nextProps.data });
  }

  getColumns = (params) => params.map((o) => (
    {
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }
  ))

  getRows = (data) => data.map((o) => (
    {
      ...o,
      key: o.id ? o.id.toString() : Math.random().toString(36),
    }
  ))

  handleTableChange = (pagination) => {
    this.setState({
      page: pagination.current,
    }, () => {
      this.props.getMethod({
        offset: pagination.current > 1 ? (pagination.current - 1) : 0,
        limit: this.state.pageSize,
      });
    });
  }

  reset = () => {
    this.setState(defaultValues);
    this.props.getMethod(defaultValues);
  }

  rowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        const { onSelectRow } = this.props;
        onSelectRow(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
      }),
    };
  }

  render() {
    const {
      columns,
      loading,
      selectable,
    } = this.props;

    const {
      total,
      page,
      pageSize,
      data,
    } = this.state;

    return (
      <div className="dataTable__wrapper">
        {selectable ? (
          <Table
            rowSelection={{
              type: 'checkbox',
              ...this.rowSelection(),
            }}
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              pageSize,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        ) : (
          <Table
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              pageSize,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        )}
      </div>
    );
  }
}

export default DataTable;
