import React from 'react';
import {
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  Menu,
  message,
  Collapse,
  Breadcrumb,
  Icon,
  Divider,
  Switch,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  AuditOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import moment from 'moment-timezone';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { EventActions, OrganizerActions } from '../../../app/redux/actions';
import {
  LoadingSelectors, EventSelectors, OrganizerSelectors,
} from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedRangePicker from '../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { eventTypeMap } from '../../../app/enum/eventType';
import { toCurrencyLocale } from '../../../app/utils/currency';

const { Panel } = Collapse;

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        eventTitle: null,
        eventType: null,
        dateEventInitial: null,
        isActive: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const {
      eventTypes,
      getEventTypes,
      organizers,
      getOrganizers,
    } = this.props;

    if (!eventTypes) {
      getEventTypes();
    }
    if (!organizers) {
      getOrganizers();
    }
  }

  actionsDropdown = (id) => (
    <Menu>
      <Menu.Item>
        <Popconfirm
          placement="left"
          title={I18n.t('shared.confirmTitle')}
          onConfirm={() => this.deleteEvent(id)}
          okText={I18n.t('shared.yes')}
          cancelText={I18n.t('shared.no')}
        >
            <Button type="link">
              {I18n.t('routes.panel.events.dataTable.columns.actions.removeText')}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item>
          <Link to={`${I18n.t('routes.panel.eventDetails.url')}${id}`}>
            <Button type="link">
              {I18n.t('routes.panel.events.dataTable.columns.actions.goToDetailsText')}
              <EditOutlined />
            </Button>
          </Link>
        </Menu.Item>
    </Menu>
  );


  getEventsPaginated = (params) => {
    const { getEventsPaginated } = this.props;

    return getEventsPaginated(params);
  }

  getEvents = (params) => {
    this.getEventsPaginated({
      ...params,
      ...this.state.params,
    });
  }

  fieldChange = (name, value) => {
    const { params } = this.state;

    if (name === 'dateEventInitial') {
      if (!value) delete params.dateEventInitial;
      else params[name] = moment(value);
    } else {
      params[name] = value || null;
    }
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getEvents(params);
  };

  cleanAdvancedFilters = () => {
    this.setState({ params: {} });
    this.getEventsPaginated({});
  }

  ticketTotalCount = (tickets) => {
    let total = 0;
    tickets.forEach((ticket) => {
      total += ticket.quantity;
    });
    return total;
  }

  deleteEvent(id) {
    this.props.deleteEvent(id, () => {
      message.success(I18n.t('routes.panel.events.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  toogleActive(oldValues, newValue) {
    this.props.toogleActive(oldValues.id, {
      ...oldValues,
      isActive: newValue
    }, () => {
      message.success(I18n.t('routes.panel.events.editSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      eventsPaginated,
      eventTypes,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')} / {I18n.t('routes.panel.events.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
            expandIconPosition="end"
          >
            <Panel
              header={I18n.t('routes.panel.events.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('eventTitle', val)}
                      placeholder={I18n.t('forms.event.title.label')}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      defaultValue={params && params.eventType}
                      value={params && params.eventType}
                      onChange={(val) => this.fieldChange('eventType', Number(val))}
                      options={eventTypes}
                      placeholder={I18n.t('forms.event.eventType.label')}
                      labelStyle={{ paddingBottom: 5 }}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedRangePicker
                      placeholder={I18n.t('forms.event.dateEvent.label')}
                      showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY HH:mm"
                      onChange={(val) => this.fieldChange('dateEventInitial', val)}
                      value={params && params.dateEventInitial}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      defaultValue={params && params.isActive}
                      value={params && params.isActive}
                      onChange={(val) => this.fieldChange('isActive', val)}
                      options={[
                        { id: "true", name: 'Ativo' },
                        { id: "false", name: 'Inativo' },
                      ]
                      }
                      placeholder={I18n.t('routes.panel.events.advancedFilters.isActive')}
                    />
                  </Col>
                  
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('routes.panel.events.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('routes.panel.events.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="users">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <AuditOutlined stroke="#fc1f55" />
                  </span>
                  {I18n.t('routes.panel.events.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administrators.addNewButtonText')}
                  href={`${I18n.t('routes.panel.eventDetails.url')}add`}
                  icon={<AuditOutlined />}
                />
              </Col>
            </Row>

            <Divider />
            <div>
              <DataTable
                getMethod={(parameters) => this.getEvents(parameters)}
                data={eventsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.title.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.title.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.dateEvent.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.dateEvent.title'),
                      render: (value) => moment(value).format('DD/MM/YYYY') || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.eventType.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.eventType.title'),
                      render: (value) => (value && eventTypeMap.get(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.totalQuantitySold.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.totalQuantitySold.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.remainingTicket.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.remainingTicket.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.totalRevenues.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.totalRevenues.title'),
                      render: (value) => toCurrencyLocale(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.isActive.key'),
                      title: I18n.t('routes.panel.events.dataTable.columns.isActive.title'),
                      render: (value, row) => (
                        <div className="dataTable__item--right">
                          <Switch
                            onChange={(value) => this.toogleActive(row, value)}
                            checked={value}
                          />
                        </div>
                      ),
                    },
                    {
                      key: I18n.t('routes.panel.events.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Link
                            to={`${I18n.t('routes.panel.eventDetails.url')}${id}`}
                            style={{ marginRight: '10px' }}
                          >
                            {I18n.t('routes.panel.events.dataTable.columns.actions.goToDetailsText')}
                            <EditOutlined />
                          </Link>
                          <Link
                            to={`${I18n.t('routes.panel.purchases.url')}${id}`}
                            style={{ marginRight: '10px' }}
                          >
                            {I18n.t('routes.panel.events.dataTable.columns.actions.purchases')}
                            <ShoppingCartOutlined />
                          </Link>
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.deleteEvent(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Button
                              type="link"
                            >
                              {I18n.t('routes.panel.events.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventsPaginated: EventSelectors.getEventsPaginated(state),
  eventTypes: EventSelectors.getEventTypes(state),
  organizers: OrganizerSelectors.getOrganizers(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEventsPaginated: (parameters) => dispatch(EventActions.getEventsPaginated(parameters)),
  getEventTypes: () => dispatch(EventActions.getEventTypes()),
  getOrganizers: () => dispatch(OrganizerActions.getOrganizers()),
  deleteEvent: (id, callback) => dispatch(EventActions.deleteEvent(id, callback)),
  toogleActive: (id, value, callback) => dispatch(EventActions.updateEvent(id, value, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Events,
);
