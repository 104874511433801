import { CouponDiscounType, CouponDurationType, CouponQuantityType, EventsScope } from "../enum/couponsType";
import { I18n } from 'react-redux-i18n';

export const getCouponTypeOptions = () => (
  [
    {
      label: I18n.t('routes.panel.discountCouponDetails.couponType.value'),
      value: CouponDiscounType.VALUE,
    },
    {
      label: I18n.t('routes.panel.discountCouponDetails.couponType.percentage'),
      value: CouponDiscounType.PERCENTAGE,
    },
  ]
);

export const getExpirationTypeOptions = () => (
  [
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.expiration.noExpire'),
      value: CouponDurationType.NO_PERIOD,
    },
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.expiration.expires'),
      value: CouponDurationType.EXPIRABLE,
    },
  ]
);

export const getQuantityTypeOptions = () => (
  [
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.quantityType.limited'),
      value: CouponQuantityType.LIMITED,
    },
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.quantityType.unlimited'),
      value: CouponQuantityType.UNLIMITED,
    },
  ]
);

export const getScopeEventTypeOptions = () => (
  [
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.eventsScopeType.allEvents'),
      value: EventsScope.ALL_EVENTS,
    },
    {
      label: I18n.t('routes.panel.discountCouponDetails.form.eventsScopeType.unicEvents'),
      value: EventsScope.UNIC_EVENT,
    },
  ]
);


