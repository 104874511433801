import React from 'react';
import {
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  Menu,
  message,
  Collapse,
  Breadcrumb,
  Icon,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import moment from 'moment-timezone';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { AuthActions, PromoterActions } from '../../../app/redux/actions';
import {
  AuthSelectors, LoadingSelectors, PromoterSelectors,
} from '../../../app/redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { GenderTypeEnum } from '../../../app/enum/genderType';

const { Panel } = Collapse;

class Promoters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        name: '',
        email: '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  actionsDropdown = (id) => (
    <Menu>
      <Menu.Item>
        <Popconfirm
          placement="left"
          title={I18n.t('shared.confirmTitle')}
          onConfirm={() => this.removePromotor(id)}
          okText={I18n.t('shared.yes')}
          cancelText={I18n.t('shared.no')}
        >
          <Button
            type="link"
          >
            {I18n.t('routes.panel.promoters.dataTable.columns.actions.removeText')}
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`${I18n.t('routes.panel.promoterDetails.url')}${id}`}
        >
          <Button
            type="link"
          >
            {I18n.t('routes.panel.promoters.dataTable.columns.actions.goToDetailsText')}
            <EditOutlined />
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  );

  getPromoters = (params) => {
    // const { me } = this.props;
    params = {
      ...params,
      profileType: ProfileType.PROMOTER,
    };
    const { getPromotersPaginated } = this.props;

    this.setState({ params });
    getPromotersPaginated(params);
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getPromoters(params);
  };

  cleanAdvancedFilters = () => this.getPromoters();

  removePromotor(id) {
    this.props.removePromotor(id, () => {
      message.success(I18n.t('routes.panel.promoters.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      promotersPaginated,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')} / {I18n.t('routes.panel.promoters.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
            expandIconPosition="right"
          >
            <Panel
              header={I18n.t('routes.panel.promoters.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('forms.user.nameFilter.label')}
                      maxLength={80}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      placeholder={I18n.t('forms.user.email.label')}
                      maxLength={80}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.cellphone}
                      onChange={(val) => this.fieldChange('cellphone', val)}
                      placeholder={I18n.t('forms.user.phone.label')}
                      maxLength={80}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('routes.panel.promoters.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('routes.panel.promoters.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="users">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <TeamOutlined />
                  </span>
                  {I18n.t('routes.panel.promoters.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.promoters.addNewButtonText')}
                  href={`${I18n.t('routes.panel.promoters.url')}add`}
                  icon={<TeamOutlined />}
                />
              </Col>
            </Row>

            <Divider />
            <div>
              <DataTable
                getMethod={(parameters) => this.getPromoters(parameters)}
                data={promotersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.promoters.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.birthday.key'),
                      title: I18n.t('routes.panel.promoters.dataTable.columns.birthday.title'),
                      render: (value) => moment(value).format('DD/MM/YYYY') || '--',
                    },
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.email.key'),
                      title: I18n.t('routes.panel.promoters.dataTable.columns.email.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.cellphone.key'),
                      title: I18n.t('routes.panel.promoters.dataTable.columns.cellphone.title'),
                      render: (value) => {
                        if (value) {
                          value = value.replace(/\D/g, '');
                          if (value.length > 10) {
                            return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
                          }
                          return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
                        }
                        return '--';
                      },
                    },
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.gender.key'),
                      title: I18n.t('routes.panel.promoters.dataTable.columns.gender.title'),
                      render: (value) => GenderTypeEnum[value]?.name || '--',
                    },
                    {
                      key: I18n.t('routes.panel.promoters.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removePromotor(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Button
                              type="link"
                            >
                              {I18n.t('routes.panel.promoters.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                          <Link to={`${I18n.t('routes.panel.promoterDetails.url')}${id}`}>
                            {I18n.t('routes.panel.promoters.dataTable.columns.actions.goToDetailsText')}
                            <EditOutlined />
                          </Link>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  promotersPaginated: PromoterSelectors.getPromotersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPromotersPaginated: (parameters, me) => dispatch(PromoterActions.getPromotersPaginated(parameters, me)),
  removePromotor: (id, callback) => dispatch(PromoterActions.removePromoter(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Promoters,
);
