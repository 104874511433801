import Axios from 'axios';
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import AuthServices from '../services/auth';
import ApiUtil, { handleAxiosError } from '../utils/api';
import { axiosHandleUnatorizedErr } from '../../index';

function getInstance({ isAuth } = {}, { contentType } = {}) {
  const auth = AuthServices.get();
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': ApiUtil.getApyKey(isAuth),
    Authorization: auth && auth.token ? `Bearer ${auth.token}` : undefined,
  };
  if (contentType) {
    headers['Content-Type'] = contentType;
    delete headers.Authorization;
  }

  const axiosInstance = Axios.create({
    baseURL: ApiUtil.getBaseUrl(isAuth),
    timeout: 600000,
    headers,
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      if (err.response.status === 401) {
        message.error(I18n.t('routes.login.errors.user_invalid_token'));
        axiosHandleUnatorizedErr();
      } else if (err.response.status === 403) {
        message.error(I18n.t('routes.login.errors.user_no_access'));
        axiosHandleUnatorizedErr();
      }
      return Promise.reject(handleAxiosError(err));
    });

  return axiosInstance;
}

export default getInstance;
