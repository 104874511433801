import React from 'react';
import AdvancedButton from '../AdvancedButton';

const CONST = {
  MAX_PERCENT: 100,
  MIN: 0,
  SUM: '+',
  MINUS: '-',
};

class AmountSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props && props.value ? props.value : 0,
      max: props.max || CONST.MAX_PERCENT,
      min: props.min || CONST.MIN,
      number: props.number || 1,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value });
  }

  onChangeValue(symbol, newValue) {
    const {
      value, max, min, number,
    } = this.state;
    const { onChange } = this.props;

    if (newValue || newValue === '') {
      let valueTemp;

      if (newValue === '') {
        valueTemp = min;
      } else {
        valueTemp = parseInt(newValue, 10);
      }

      if (max && valueTemp > max) {
        valueTemp = max;
      }

      if (min && valueTemp < min) {
        valueTemp = min;
      }

      onChange(valueTemp);
      return this.setState({ value: valueTemp });
    }

    let valueTemp = value;

    if (symbol === CONST.MINUS && value > min) {
      valueTemp -= number;
    } else if (symbol === CONST.SUM) {
      valueTemp += number;
    }
    onChange(valueTemp);
    this.setState({ value: Number(valueTemp.toFixed(2)) });
  }

  render() {
    const { value, max, min } = this.state;

    const {
      prefix, suffix, description, disabled = false, maxLength,
    } = this.props;

    return (
      <div className="amount-selection">
        <p className="amount-selection__description">{description}</p>
        <h2 className="amount-selection__amount">
          <span
            className={`amount-selection__amount__prefix
          ${prefix ? 'amount-selection__amount--visible' : ''}
          ${disabled ? 'amount-selection--disabled' : ''}
          `}
          >
            {prefix}
          </span>
          <span className="amount-selection__amount__value">
            <input
              className={`amount-selection__amount__value__input
              ${disabled ? 'amount-selection--disabled' : ''}`}
              style={{
                width: value ? value && value.toString().length * 20 : 30,
              }}
              value={value}
              onChange={(e) => this.onChangeValue(null, e.target.value)}
              disabled={disabled}
              maxLength={maxLength}
            />
          </span>
          <span
            className={`amount-selection__amount__suffix
          ${suffix ? 'amount-selection__amount--visible' : ''}
          ${disabled ? 'amount-selection--disabled' : ''}
          `}
          >
            {suffix}
          </span>
        </h2>
        <div className="amount-selection__actions">
          <span className="amount-selection__actions__button">
            <AdvancedButton
              onClick={() => this.onChangeValue(CONST.MINUS)}
              text={CONST.MINUS}
              type="primary"
              disabled={disabled || value === min}
              shape="circle"
            />
          </span>
          <span className="amount-selection__actions__button">
            <AdvancedButton
              onClick={() => this.onChangeValue(CONST.SUM)}
              text={CONST.SUM}
              type="primary"
              disabled={disabled || (max && value === max)}
              shape="circle"
            />
          </span>
        </div>
      </div>
    );
  }
}

export default AmountSelection;
