/* eslint-disable max-len */
import moment from 'moment-timezone';
import EventRequests from '../../api/event';
import HandleErrors from '../../services/handleErrors';

import {
  addLoading,
  removeLoading,
} from './loading';

export const ACTION_SAVE_EVENTS_PAGINATED = 'ACTION_SAVE_EVENTS_PAGINATED';
export const ACTION_SAVE_EVENT_DETAILS = 'ACTION_SAVE_EVENT_DETAILS';
export const ACTION_SAVE_EVENT_TYPES = 'ACTION_SAVE_EVENT_TYPES';
export const ACTION_SAVE_EVENT_SALES = 'ACTION_SAVE_EVENT_SALES';

export const getEventsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  const params = {
    ...parameters,
    limit: parameters.pageSize || 10,
    offset: parameters.offset || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  if (parameters.dateEventInitial) params.dateEventInitial = moment(parameters.dateEventInitial).format('YYYY-MM-DD HH:mm:ss');

  try {
    const eventsPaginated = await EventRequests.getEventsPaginated(params);

    dispatch({
      type: ACTION_SAVE_EVENTS_PAGINATED,
      payload: eventsPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEventsAndReturn = (parameters, preventLoading) => async (dispatch) => {
  if(!preventLoading) dispatch(addLoading());
  const params = {
    ...parameters,
    limit: 30,
    pageSize: 30,
    offset: 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const events = await EventRequests.getEventsPaginated(params);
    return events?.rows || []
  } catch (err) {
    HandleErrors(err);
    return []
  } finally {
    if(!preventLoading) dispatch(removeLoading());
  }
};

export const getEventDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    if (id) {
      const { eventOperator, ...event } = await EventRequests.getEventById(id);

      const eventOperatorFiltered = eventOperator && eventOperator.map((op) => ({
        ...op.operator,
        operatorId: op.operator.id,
      }));

      dispatch({
        type: ACTION_SAVE_EVENT_DETAILS,
        payload: { operatorEvent: eventOperatorFiltered, ...event },
      });
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEventTypes = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const eventTypes = await EventRequests.getEventTypes();
    dispatch({
      type: ACTION_SAVE_EVENT_TYPES,
      payload: eventTypes,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createEvent = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await EventRequests.createEvent(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
export const updateEvent = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await EventRequests.updateEvent(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
export const deleteEvent = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await EventRequests.deleteEvent(id);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEventSales = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    parameters = {
      ...parameters,
      limit: parameters.pageSize || 10,
      offset: parameters.offset || 0,
      isDESC: true,
      ...(parameters.createdAt && { createdAt: `${moment(parameters.createdAt[0]).format()},${moment(parameters.createdAt[1]).format()}` }),
    };
    const eventSales = await EventRequests.getEventSales(parameters);

    if (eventSales) {
      dispatch({
        type: ACTION_SAVE_EVENT_SALES,
        payload: eventSales,
      });
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEventSalesPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    parameters = {
      ...parameters,
      limit: parameters.pageSize || 10,
      offset: parameters.offset || 0,
      isDESC: true,
    };
    const eventSales = await EventRequests.getEventSales(parameters);
    dispatch({
      type: ACTION_SAVE_EVENT_SALES,
      payload: eventSales,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getEventSalesDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    if (id) {
      const data = await EventRequests.getEventSalesDetails(id);

      dispatch({
        type: ACTION_SAVE_EVENT_DETAILS,
        payload: data,
      });
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const setRefund = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    if (parameters) {
      await EventRequests.setRefund(parameters.id, parameters.amount);
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
