import Compress from 'compress.js';
import Config from '../config/app.config';

export async function compressImages(images) {
  let response = null;
  try {
    const compress = new Compress();

    response = await compress.compress(images, {
      quality: Config.imageCompression.quality,
      maxWidth: Config.imageCompression.maxWidth,
      size: 1,
      resize: true,
    });
    response = response ? `${response[0].prefix}${response[0].data}` : null;
  } catch (e) {
    response = e;
  }
  return response;
}
