import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider, Icon,
  Layout, message, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { LockOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { UserActions } from '../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { ViewType } from '../../../app/enum/viewType';
import AdministratorForm from '../../../app/forms/administrator/AdministratorForm';

class AdministratorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
    };
  }

  componentDidMount() {
    const { getUserDetails, id } = this.props;

    this.setState({
      viewType: (id && id !== 'add') ? ViewType.EDIT : ViewType.ADD,
    });

    getUserDetails(id !== 'add' ? id : null);
  }

  updateUser(id, data) {
    const { updateUser, createUser, userDetails } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        createUser(data, () => {
          message.success(I18n.t('routes.panel.administratorDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.administrators.url'));
        });
      } else {
        updateUser(userDetails.id, data, () => {
          message.success(I18n.t('routes.panel.administratorDetails.messages.success'));
          navigate(I18n.t('routes.panel.administrators.url'));
        });
      }
    } catch (e) {
      message.error(I18n.t('routes.panel.administratorDetails.messages.errors.generic'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType } = this.state;
    const {
      userDetails,
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.administrators.url')}>
                <Icon type="lock" /> <span>{I18n.t('routes.panel.administrators.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.administratorDetails.pageTitleAdd'
                      : 'routes.panel.administratorDetails.pageTitle',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrator-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <LockOutlined />
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.administratorDetails.pageTitleAdd'
                        : 'routes.panel.administratorDetails.pageTitle',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.administratorDetails.pageDescriptionAdd'
                        : 'routes.panel.administratorDetails.pageDescription',
                    )
                  }
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>
                    {(userDetails || viewType === ViewType.ADD) && (
                      <AdministratorForm
                        id={id}
                        submitFunction={(adminId, data) => this.updateUser(adminId, data)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: UserSelectors.getUserDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  createUser: (data, callback) => dispatch(UserActions.createAdmin(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AdministratorDetails,
);
