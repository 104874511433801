import HandleErrors from '../../services/handleErrors';

import {
  addLoading,
  removeLoading,
} from './loading';
import PushNotificationRequests from '../../api/push-notification';
import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_USERS_FOR_PUSH_PAGINATED = 'ACTION_SAVE_USERS_FOR_PUSH_PAGINATED';

export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const getUsersForPushNotification = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    pageSize: parameters.pageSize || 10,
    offset: parameters.offset || 0,
    orderBy: 'createdAt',
    desc: true,
  };

  parameters = {
    ...parameters,
    profileType: ProfileType.USER,
  };

  try {
    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: null,
    });

    const usersPaginated = await PushNotificationRequests.getUsersForPushPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
export const createNotification = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    parameters = {
      ...parameters,
      profileType: [2, 1],
    };
    await PushNotificationRequests.createNotification(parameters);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
