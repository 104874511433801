/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import {
  LoadingSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';

import { ViewType } from '../../enum/viewType';
import { getCouponTypeOptions, getExpirationTypeOptions, getQuantityTypeOptions, getScopeEventTypeOptions } from '../../utils/radio';
import { CouponDiscounType, CouponDurationType, CouponQuantityType, EventsScope } from '../../enum/couponsType';
import AdvancedRadio from '../../../components/shared/AdvancedRadio/AdvancedRadio';
import AdvancedRangePickerWithEnd, { dateFormat } from '../../../components/shared/AdvancedRangePickerWithEnd/AdvancedRangePickerWithEnd';
import AdvancedLazySelect from '../../../components/shared/AdvancedLazySelect/AdvancedLazySelect';
import { EventActions } from '../../redux/actions';
import AdvancedRangePicker from '../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';

class CouponsForm extends React.Component {
  constructor(props) {
    super(props);

    const dt = props.data;
    this.state = {
      changePasswordVisible: false,
      form: {
        title: dt?.title,
        value: dt?.discountType === CouponDiscounType.VALUE ? dt?.value : null,
        percentageValue: dt?.discountType === CouponDiscounType.PERCENTAGE && dt?.value,
        type: dt?.discountType || CouponDiscounType.VALUE,
        initialQuantity: dt?.initialQuantity,
        currentQuantity: dt?.currentQuantity,
        validity: dt && [
          moment(dt.startAt, 'YYYY-MM-DD'),
          moment(dt.endAt, 'YYYY-MM-DD'),
        ],
        startAt: dt?.startAt,
        endAt: dt?.endAt,
        eventId: dt?.event?.id,
        durationType: dt?.durationType || CouponDurationType.EXPIRABLE,
        quantityType: dt?.quantityType || CouponQuantityType.LIMITED,
        eventScopeType: dt?.event ? EventsScope.UNIC_EVENT : EventsScope.ALL_EVENTS
      },
      eventsInSelect: dt?.event ? [{
        id: dt?.event.id,
        title: dt?.event.title
      }] : []
    };
  }

  componentDidMount() {
    this.handleSearchEvent('')
  }

  onSubmit (e) {
    e.preventDefault();

    const { form } = this.state;
    const { data, submitFunction } = this.props;

    if (form && !form.title) {
      return message.error(I18n.t('errors.invalid_code'));
    }

    if (form.durationType === CouponDurationType.EXPIRABLE && (!form.startAt || !form.endAt)) {
      return message.error(I18n.t('errors.invalid_validity'));
    }


    if (form.eventScopeType === EventsScope.UNIC_EVENT && !form.eventId) {
      return message.error(I18n.t('errors.invalid_event'));
    }

    if(form.quantityType === CouponQuantityType.LIMITED && !(form.currentQuantity || form.initialQuantity)) {
      return message.error(I18n.t('errors.invalid_quantity'))
    }

    if (
      form.type === CouponDiscounType.PERCENTAGE && 
      (!form.percentageValue || !(Number(form.percentageValue) > 0))
    ) {
      return message.error(I18n.t('errors.invalid_percen'))
    }

    if (
      form.type === CouponDiscounType.VALUE && 
      (!form.value || !(Number(form.value) > 0))
    ) {
      return message.error(I18n.t('errors.invalid_value'))
    }

    const payload = {
      ...form,
      validity: null,
      discountType: form.type,
      initialQuantity: Number(form.initialQuantity),
      currentQuantity: (form?.currentQuantity || Number(form?.currentQuantity) === 0) 
        ? Number(form.currentQuantity)
        : Number(form.initialQuantity),
      eventScopeType: null,
      percentageValue: null,
      endAt: form.durationType === CouponDurationType.NO_PERIOD ? null : form.endAt,
      type: null,
      value: form.type === CouponDiscounType.PERCENTAGE ? form.percentageValue : form.value,
    };

    if(!payload.endAt) {
      delete payload.endAt
    }

    try {
      submitFunction(data ? data.id : null, payload);
    } catch (error) {
      console.log(error);
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  valueTypeChange (valueType) {
    this.fieldChange('type', valueType);

    if (valueType === CouponDiscounType.VALUE) {
      this.fieldChange('value', null);
    } else {
      this.fieldChange('percentageValue', null);
    }
  }

  validityChange (dates) {
    this.fieldChange('validity', dates);
    const { form } = this.state;
    form.startAt = dates[0]?.format('YYYY-MM-DD');
    form.endAt = dates[1]?.format('YYYY-MM-DD');
    this.setState({ form });
  }

  quantityChange (quantity) {
    this.fieldChange('initialQuantity', quantity);
    this.fieldChange('currentQuantity', quantity);
  }

  handleChangeQuantityType (quantityType) {
    if (quantityType === CouponQuantityType.UNLIMITED) {
      this.fieldChange('initialQuantity', null);
      this.fieldChange('currentQuantity', null);
    }
    this.fieldChange('quantityType', quantityType)
  }

  async handleSearchEvent (eventTitle) {
    const response = await this.props.getEventsPaginated(
      { eventTitle },
      true
    )
    this.setState({ eventsInSelect: response })
  }

  handleScopeChange (currentScopeType) {
    if (currentScopeType === EventsScope.ALL_EVENTS) {
      this.fieldChange('eventId', null)
    }

    this.fieldChange('eventScopeType', currentScopeType)
  }

  render() {
    const {
      loading, viewType
    } = this.props;
    const { form, eventsInSelect } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="couponForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('routes.panel.discountCouponDetails.form.expiration.label')}
                    options={getExpirationTypeOptions()}
                    value={form?.durationType}
                    optionType="default"
                    onChange={(val) => this.fieldChange('durationType', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('routes.panel.discountCouponDetails.form.quantityType.label')}
                    options={getQuantityTypeOptions()}
                    value={form?.quantityType}
                    optionType="default"
                    onChange={(val) => this.handleChangeQuantityType(val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('routes.panel.discountCouponDetails.form.eventsScopeType.label')}
                    options={getScopeEventTypeOptions()}
                    value={form?.eventScopeType}
                    optionType="default"
                    onChange={(val) => this.handleScopeChange(val)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.discountCouponDetails.form.code.label')}
                    value={form && form.title}
                    onChange={(val) => this.fieldChange('title', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('routes.panel.discountCouponDetails.form.discountType.label')}
                    options={getCouponTypeOptions()}
                    value={form && form.type}
                    optionType="default"
                    onChange={(val) => this.valueTypeChange(val)}
                  />
                </Col>
                <Col span={8}>
                  {form?.type === CouponDiscounType.VALUE
                    ? (
                      <AdvancedInput
                        label={I18n.t('routes.panel.discountCouponDetails.form.value.label')}
                        value={form?.value}
                        onChange={(val) => this.fieldChange('value', val)}
                      />
                    ) : (
                      <AdvancedInput
                        label={I18n.t('routes.panel.discountCouponDetails.form.percentage.label')}
                        value={form?.percentageValue}
                        onChange={(val) => this.fieldChange('percentageValue', val)}
                        max={100}
                      />
                    )
                  }
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  {form.durationType === CouponDurationType.EXPIRABLE ? (
                    <AdvancedRangePickerWithEnd
                      label={I18n.t('routes.panel.discountCouponDetails.form.validityRange.label')}
                      value={form.validity}
                      onChange={(val) => this.validityChange(val)}
                    />
                  ) : (
                    <AdvancedRangePicker
                      label={I18n.t('routes.panel.discountCouponDetails.form.validityStart.label')}
                      value={form.validity && form.validity[0]}
                      onChange={(val) => this.validityChange([val, null])}
                      placeholder={I18n.t('routes.panel.discountCouponDetails.form.validityStart.placeholder')}
                      format={dateFormat}
                    />
                  )}
                </Col>
                {viewType === ViewType.ADD
                  ? (
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t('routes.panel.discountCouponDetails.form.initialQuantity.label')}
                        value={form?.initialQuantity}
                        onChange={(val) => this.quantityChange(val)}
                        type="number"
                        disabled={form?.quantityType === CouponQuantityType.UNLIMITED}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col span={8}>
                        <AdvancedInput
                          label={I18n.t('routes.panel.discountCouponDetails.form.initialQuantity.label')}
                          value={form?.initialQuantity}
                          onChange={(val) => this.fieldChange('initialQuantity', val)}
                          disabled={form?.quantityType === CouponQuantityType.UNLIMITED}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedInput
                          label={I18n.t('routes.panel.discountCouponDetails.form.currentQuantity.label')}
                          value={form?.currentQuantity}
                          onChange={(val) => this.fieldChange('currentQuantity', val)}
                          disabled
                        />
                      </Col>
                    </>
                  )
                }
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedLazySelect
                    options={eventsInSelect}
                    label={I18n.t('routes.panel.discountCouponDetails.form.event.label')}
                    placeholder={I18n.t('routes.panel.discountCouponDetails.form.event.placeholder')}
                    onChange={(val) => this.fieldChange('eventId', val)}
                    disabled={form.eventScopeType === EventsScope.ALL_EVENTS}
                    value={form && form.eventId}
                    onSearch={(title) => this.handleSearchEvent(title)}
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t("routes.panel.discountCoupon.url")}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEventsPaginated: (parameters, preventLoading) => dispatch(
    EventActions.getEventsAndReturn(parameters, preventLoading)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CouponsForm,
);
