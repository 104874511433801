import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_PROMOTERS_PAGINATED,
  ACTION_SAVE_PROMOTER_DETAILS,
} from '../actions/promoter';

const initialState = Immutable({
  promotersPaginated: null,
  promoterDetails: null,
});

export default function promoter(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_PROMOTERS_PAGINATED:
      state = {
        ...state,
        promotersPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_PROMOTER_DETAILS:
      state = {
        ...state,
        promoterDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPromotersPaginated(state) {
  return state.promoter.promotersPaginated;
}

export function getPromoterDetails(state) {
  return state.promoter.promoterDetails;
}
