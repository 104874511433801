import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  DatePicker,
} from 'antd';

const { RangePicker } = DatePicker;

export const dateFormat = 'DD/MM/YYYY HH:mm';

const AdvancedRangePickerWithEnd = ({
  label,
  onChange,
  disabled,
  value
}) => (
  <div className="advanced-rangepicker">
    <label className="advanced-rangepicker__label">
      <span className="advanced-rangepicker__label__inner">{label}</span>
      <RangePicker
        value={value}
        disabled={disabled}
        format={dateFormat}
        onChange={onChange}
        placeholder={[
          I18n.t('routes.panel.events.advancedFilters.rangeStart'),
          I18n.t('routes.panel.events.advancedFilters.rangeEnd'),
        ]}
        style={
          { width: '100%' }
        }
      />
    </label>
  </div>
);

export default AdvancedRangePickerWithEnd;
