import getInstance from './config';

export default class UserApi {
  static async getUsersPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async getUserDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}`);
    return data;
  }

  static async createUser(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/adm', userData);
    return data;
  }

  static async update(userData) {
    const instance = await getInstance();
    const { data } = await instance.put('/user', userData);
    return data;
  }

  static async delete(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/user/${id}`);
    return data;
  }
}
