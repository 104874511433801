import React, { useEffect, useState } from 'react';
import {
  Select,
  Empty,
  Avatar,
} from 'antd';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

const AdvancedLazySelect = (
  {
    value,
    defaultValue,
    options,
    label,
    onChange,
    disabled,
    placeholder,
    disableSearch,
    onSearch,
    mode,
    labelStyle,
    dropdownClassName,
    optionClassName,
    searchDelay
  },
) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [renderedFirstTime, setRenderedFirstTime] = useState(false)

  useEffect(() => {
    setRenderedFirstTime(true)
  }, [])

  useEffect(() => {
    if(!renderedFirstTime) return;

    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm)
    }, searchDelay || 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <div className="advanced-select">
      <label className="advanced-select__label">
        <span
          style={labelStyle}
          className="advanced-select__label__inner"
        >
          {label}
        </span>
        <Select
          style={{ width: '100%' }}
          value={value || undefined}
          defaultValue={defaultValue || undefined}
          onChange={onChange}
          optionFilterProp={!!onSearch ? 'children' : undefined}
          disabled={disabled || false}
          placeholder={placeholder || I18n.t('shared.selectSomeValue')}
          showSearch={!disableSearch || false}
          allowClear
          mode={mode || undefined}
          popupClassName={dropdownClassName || ''}
          onSearch={onSearch ? (val) => setSearchTerm(val) : undefined}
          notFoundContent={
            (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={I18n.t('shared.notFoundSearch')}
              />
            )
          }
          filterOption={disableSearch ? (
            input,
            option,
          ) => (
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          ) : undefined}
        >
          {options && options.length > 0 && options.map((a) => (
            <Option
              key={a.id.toString()}
              value={a.id}
              className={optionClassName || ''}
            >
              {a.title}
            </Option>
          ))}
        </Select>
      </label>
    </div>
  )
};

export default AdvancedLazySelect;
