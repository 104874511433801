import React from "react";
import { Col } from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import AdvancedButton from "../../components/shared/AdvancedButton/AdvancedButton";
import Logo from "../../app/assets/img/logoLuppi.svg";

class AskForAppToMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToApp = () => {
    const url = window.location.href;
    const eventId = url.split("/").pop();
    var userAgent = window.navigator.userAgent;
    var isMobile = {
      android: () => {
        return /Android/i.test(userAgent);
      },
      ios: () => {
        return /iPhone|iPad|iPod/i.test(userAgent);
      },
    };
    if (isMobile.android()) {
      window.location.href = `https://luppi.app/events/${eventId}`;
    }
    if (isMobile.ios()) {
      window.location.href = `luppi://events/${eventId}`;
    }
  };

  navigateToStore = () => {
    window.location.href = "https://luppi.page.link/zRx8";
  };

  render() {
    return (
      <div>
        <div className="asking__logoImageContainer">
          <img className="asking__logoImage" src={Logo} />
        </div>
        <Col md={12} className="asking__container">
          <div className="asking__modalContainer">
            <div className="asking__modalContent">
              <div className="asking__modalTitle">
                <div className="asking__modalImage">
                  <AiFillQuestionCircle color="#FE2E52" size={25} />
                </div>
                <strong>Atenção</strong>
              </div>
              <div className="asking__modalText">
                <p>Você já tem o app Luppi instalado em seu celular?</p>
              </div>
              <div className="asking__buttonsContainer">
                <AdvancedButton type="white" text="Preciso instalar" onClick={this.navigateToStore} />
                <AdvancedButton text="Já tenho" onClick={this.navigateToApp} />
              </div>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default AskForAppToMobile;
