import React from 'react';
import {
  Col,
  Layout, message, Row,
  Spin, Breadcrumb,
} from 'antd';
import { connect } from 'react-redux';
import { navigate, Link } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { OrganizerActions } from '../../../app/redux/actions';
import { LoadingSelectors, OrganizerSelectors } from '../../../app/redux/reducers';
import OrganizerForm from '../../../app/forms/organizers/OrganizersForm';
import { ViewType } from '../../../app/enum/viewType';

class OrganizerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      organizerDetails: this.props.organizerDetails,
    };
  }

  componentDidMount() {
    const {
      getOrganizerDetails, id,
    } = this.props;
    if (!id || id === 'add') {
      this.setState({
        viewType: ViewType.ADD,
      });
    } else {
      getOrganizerDetails(id);
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      organizerDetails: props.organizerDetails,
    };
  }

  async updateOrganizer(id, data) {
    const { updateOrganizerById, createOrganizer } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await createOrganizer(data, () => {
          message.success(I18n.t('routes.panel.organizerDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.organizerDetails.url'));
        });
      } else {
        await updateOrganizerById(id, data, () => {
          message.success(I18n.t('routes.panel.organizerDetails.messages.success'));
          navigate(I18n.t('routes.panel.organizerDetails.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, organizerDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.organizers.url')}>
                <span>{I18n.t('routes.panel.organizers.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.organizerDetails.pageTitleAdd'
                      : 'routes.panel.organizerDetails.pageTitle',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="user-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div>
                <Row>
                  <Col span={24}>
                    {(organizerDetails || viewType === ViewType.ADD) && (
                      <OrganizerForm
                        data={organizerDetails}
                        submitFunction={(userId, data) => this.updateOrganizer(userId, data)}
                        viewType={viewType}
                        id={id}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  organizerDetails: OrganizerSelectors.getOrganizerDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizerDetails: (id) => dispatch(OrganizerActions.getOrganizerDetails(id)),
  updateOrganizerById: (id, data, callback) => dispatch(OrganizerActions.updateOrganizer(id, data, callback)),
  createOrganizer: (data, callback) => dispatch(OrganizerActions.createOrganizer(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  OrganizerDetails,
);
