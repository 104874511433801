export default {
  auth: {
    path: "https://auth.luppi.app/api",
  },
  localhost: {
    path: "https://api-dev.luppi.app/api",
    apiKey: "bad85bdd-7f5d-498b-a9a3-c61fbabe2e93",
  },
  development: {
    path: "https://api-dev.luppi.app/api",
    apiKey: "bad85bdd-7f5d-498b-a9a3-c61fbabe2e93",
  },
  homolog: {
    path: "https://api-hom.luppi.app/api",
    apiKey: "eda6a5b3-5c96-4f26-887d-cdeb668d6b10",
  },
  production: {
    path: "https://api.luppi.app/api",
    apiKey: "0af41869-f519-4640-a8e9-113d57c55e76",
  },
};
