import {
  ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
} from '../actions/push-notification';

const initialState = {
  usersForPushNotificationPaginated: null,
};

export default function pushNotification(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_USERS_FOR_PUSH_PAGINATED:
      state = {
        ...state,
        usersForPushNotificationPaginated: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getUsersForPushNotificationPaginated(state) {
  return state.pushNotification.usersForPushNotificationPaginated;
}
