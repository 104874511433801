export default {
  application: {
    name: "Luppi - Dashboard",
    shortDescription: "Luppi",
    title: "Home",
    version: {
      title: "Versão",
      number: "1.0.0",
    },
    footer: {
      poweredBy: "Powered by",
      poweredByBrand: "Luppi",
      poweredByBrandUrl: "https://mblabs.com.br/",
    },
    percent: "%",
  },
  shared: {
    noAccess: "Você não tem acesso a esta página, por favor tente novamente.",
    notFoundSearch: "Nada encontrado.",
    selectSomeValue: "Selecione...",
    typeToContinue: "Digite algo para pesquisar...",
    type: "Digite...",
    phoneMask: "(99) 99999-9999",
    zipCodeMask: "99999-999",
    cpfMask: "999.999.999-99",
    cnpjMask: "99.999.999/9999-99",
    backButtonText: "Voltar",
    saveButtonText: "Salvar",
    yes: "Sim",
    no: "Não",
    hi: "Olá",
    save: "Salvar",
    cancel: "Cancelar",
    loading: "Carregando...",
    confirmTitle: "Você tem certeza?",
    logout: {
      menuText: "Sair do sistema",
      confirmation: "Você tem certeza que deseja sair?",
      confirmButton: "Tenho certeza, sair.",
    },
    nothingToShow: "Nada para exibir, verifique os parâmetros e tente novamente.",
    hours: "horas",
    minutes: "minutos",
    seconds: "segundos",
    disclaimerModal: {
      okText: "Entendi",
      cancelText: "Não desejo prosseguir, sair",
    },
    distanceUnit: "metros",
    distanceUnitSmall: "m",
    formatDate: "DD/MM/YYYY HH:mm",
    decimalSeparator: ",",
    thousandSeparator: ".",
    moneyPrefix: "R$ ",
  },
  errors: {
    required_fields: "Por favor preencha todos os campos",
    cpf_already_registerd: "CPF já cadastrado",
    email_already_used: "E-mail já cadastrado",
    invalid_password: "Senha inválida (mínimo de 6 caracteres).",
    invalid_email: "E-mail inválido, verifique e tente novamente.",
    invalid_telephone: "Telefone inválido",
    invalid_plan_expiry: "Data de vencimento inválida",
    invalid_city: "Cidade inválida",
    invalid_address: "Endereço inválido",
    invalid_cep: "CEP inválido",
    invalid_cpf: "CPF inválido",
    invalid_cellphone: "Whatsapp inválido",
    invalid_name: "Nome inválido (mínimo 5 caracteres).",
    user_not_found: "O email ou senha estão incorretos",
    generic: "Algo deu errado, verifique os campos tente novamente.",
    email: "E-mail inválido, verifique e tente novamente.",
    date: "A data inserida é inválida",
    passwordsMismatch: "A senha e confirmação de senha não estão iguais.",
    passwordsRequired: "A senha e confirmação de senha são requeridas.",
    name: "O nome inserido é inválido",
    password: "As senhas inseridas são inválidas. Devem ter no mínimo 6 caracteres",
    cellphone: "O Celular inserido é inválido!",
    cellphone_already_registerd: "Whatsapp já cadastrado",
    not_possible_to_register_more_tickets: "O limite máximo de ingressos estabelecido esta sendo excedido",
    only_captured_or_partially_refunded_can_be_refunded: "Somente compras parcialmente estornadas ou capturadas podem ser receber estorno.",
    invalid_code: "Código inválido.",
    invalid_quantity: "Quantidade inválida.",
    invalid_validity: "Validade inválida",
    invalid_event: "Evento inválido",
    invalid_percen: "Porcentagem inválida",
    invalid_value: "Valor inválido"
  },
  routes: {
    login: {
      url: "/",
      pageTitle: "Entrar",
      content: {
        email: "E-mail",
        emailPlaceholder: "Entre com seu e-mail",
        password: "Senha",
        passwordPlaceholder: "Sua senha",
        continueButton: "Entrar no sistema",
        welcome: "Bem vindo(a) novamente.",
      },
      errors: {
        fields: "Verifique os campos e tente novamente",
        invalid_credentials: "Credenciais inválidas, verifique e tente novamente.",
        user_not_found: "Credenciais inválidas, verifique e tente novamente.",
        user_invalid_token: "Token inválido ou expirado. Entre novamente.",
      },
      messages: {
        welcome: "Bem vindo(a) novamente.",
      },
    },

    askToMobile: {
      url: "/verificacao/:id",
      asking: "Você tem o Luppi no seu celular?",
      pageTitle: "Asking",
    },

    panel: {
      pageTitle: "Dashboard",
      administrative: "Administrativo",
      administrators: {
        sidebarTitle: "Administradores",
        url: "/painel/administradores",
        pageTitle: "Administradores",
        deleteSucces: "Administrador removido com sucesso.",
        addNewButtonText: "Adicionar novo",
        dataTable: {
          columns: {
            name: {
              key: "name",
              title: "Nome",
            },
            email: {
              key: "email",
              title: "E-mail",
            },
            profileType: {
              key: "profileType",
              title: "Tipo de conta",
            },
            createdAt: {
              key: "createdAt",
              title: "Data de cadastro",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
            },
          },
        },
      },
      discountCoupon: {
        url: "/painel/cupom",
        pageTitle: "Cupons",
        sidebarTitle: "Cupons",
        dataTable: {
          columns: {
            code: {
              key: "title",
              title: "Código",
            },
            event: {
              key: "event",
              title: "Evento",
            },
            value: {
              key: "value",
              title: "Valor",
            },
            couponType: {
              key: "discountType",
              title: "Tipo de cupom",
              percentageType: "Porcentagem",
              valueType: "Valor"
            },
            startAt: {
              key: "startAt",
              title: "Data de Início",
            },
            endAt: {
              key: "endAt",
              title: "Data de expiração",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
            },
          },
        },
        messages: {
          addSuccess: 'Cupom adicionado com sucesso',
          deleteSuccess: 'Cupom deletado com sucesso'
        }
      },
      discountCouponDetails: {
        pageTitleAdd: 'Adicionar Cupom',
        pageTitle: 'Detalhes do cupom',
        pageDescription: "Preencha os campos abaixo para editar um cupom.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo cupom",
        couponType: {
          value: 'Valor',
          percentage: 'Porcentagem'
        },
        form: {
          code: {
            label: 'Código'
          },
          discountType: {
            label: 'Tipo de cupom'
          },
          value: {
            label: 'Valor do cupom em R$'
          },
          percentage: {
            label: 'Valor do cupom em %'
          },
          validityRange: {
            label: 'Validade de até'
          },
          validityStart: {
            label: 'Válido de',
            placeholder: 'Data inicial'
          },
          initialQuantity: {
            label: 'Quantidade inicial'
          },
          currentQuantity: {
            label: 'Quantidade atual'
          },
          event: {
            label: 'Evento',
            placeholder: 'Selecione o evento'
          },
          expiration: {
            label: 'Expiração',
            expires: 'Expira',
            noExpire: 'Não expira'
          },
          quantityType: {
            label: 'Quantidade',
            limited: 'Limitada',
            unlimited: 'Ilimitada'
          },
          eventsScopeType: {
            label: 'Abrangência',
            allEvents: 'Todos os Eventos',
            unicEvents: 'Eventos Único',
          }
        },
        messages: {
          successEdit: "Cupom editado com sucesso"
        }
      },
      users: {
        sidebarTitle: "Usuários",
        sidebarItemRegister: "Cadastrar",
        sidebarItemList: "Lista",
        url: "/painel/usuarios/",
        pageTitle: "Usuários",
        addNewButtonText: "Adicionar novo",
        userImportCsvButtonText: "Importar CSV",
        deleteSucces: "Cliente removido com sucesso.",
        matches: "matches",
        match: "match",
        advancedFilters: {
          title: "Busca avançada",
          filterButtonText: "Filtrar",
          clearButtonText: "Limpar filtros",
        },
        userImportCsvModal: {
          okText: "Realizar upload",
          cancelText: "Cancelar",
          title: "Importar clientes por CSV",
          templateButtonOne: "Clique aqui",
          templateButtonTwo: "para realizar o download do template de importação.",
          selectFile: "Clique aqui ou arraste arquivos",
          selectFileDescription: "Tipo de arquivo permitido: .csv de até 2mb",
          errors: {
            invalidFileSize: "Arquivo muito grande, divida em um menor e tente novamente.",
            maxSelectedFiles: "Apenas um arquivo por vez.",
            generic: "Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.",
            genericUpload: "Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.",
            login_already_used: "Já existe um cliente com o login informado, verifique e tente novamente.",
            invalid_id: "Selecione a empresa.",
          },
          success: "Clientes(s) importado(s) com sucesso.",
        },
        dataTable: {
          columns: {
            photoUrl: {
              key: "photoUrl",
            },
            name: {
              key: "name",
              title: "Nome",
            },
            email: {
              key: "email",
              title: "E-mail",
            },
            birthday: {
              key: "birthday",
              title: "Data de nascimento",
            },
            gender: {
              key: "gender",
              title: "Gênero",
            },
            status: {
              key: "status",
              title: "Status",
            },
            cellphone: {
              key: "cellphone",
              title: "Celular",
            },
            cep: {
              key: "cep",
              title: "CEP",
            },
            city: {
              key: "city",
              title: "Cidade",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
            },
          },
        },
        errors: {
          generic: "Algo deu errado, tente novamente mais tarde.",
        },
      },
      organizers: {
        sidebarTitle: "Organizadores",
        url: "/painel/organizadores",
        pageTitle: "Organizadores",
        title: "Organizadores",
        deleteSuccess: "Organizador deletado com sucesso!",
        dataTable: {
          columns: {
            name: {
              key: "name",
              title: "Nome",
            },
            fantasyName: {
              key: "promoterData",
              title: "Nome fantasia",
            },
            taxIdentifier: {
              key: "doc",
              title: "CNPJ/CPF",
            },
            contact: {
              key: "cellphone",
              title: "Contato",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
            },
          },
        },
      },
      organizerDetails: {
        url: "/painel/organizadores/",
        pageTitle: "Detalhes do organizador",
        pageTitleAdd: "Cadastrar organizador",
        pageDescription: "Preencha os campos abaixo para editar um organizador.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo organizador",
        changePasswordPhrase: "Desejo alterar a senha do usuário",
        organizerSection: "Dados do organizador",
        contactSection: "Contato",
        settingsSection: "Configurações",
        subAccountSection: "Dados da conta bancária",
        form: {
          name: {
            label: "Nome",
          },
          fantasyName: {
            label: "Nome fantasia",
          },
          docCnpj: {
            label: "CNPJ",
          },
          docCpf: {
            label: "CPF",
          },
          noCnpj: {
            label: "Desejo usar CPF",
          },
          zipcode: {
            label: "CEP",
          },
          neighborhood: {
            label: "Bairro",
          },
          street: {
            label: "Rua",
          },
          city: {
            label: "Cidade",
          },
          uf: {
            label: "UF",
          },
          addressNumber: {
            label: "Número",
          },
          cellphone: {
            label: "Celular",
          },
          luppiCommission: {
            label: "Valor da porcentagem do Luppi",
          },
          bank: {
            label: "Banco",
          },
          bankAgency: {
            label: "Agência bancária",
          },
          accountType: {
            label: "Tipo de Conta",
          },
          bankAccount: {
            label: "Conta bancária *",
            observation: `*OBS: Se você inseriu corretamente o número da Conta bancária e a máscara não ficou completa, insira
            o número "0" a esquerda até a máscara ficar completa. Por exemplo: Transforme "1234_-_" para
            "00123-4"`,
          },
        },
        messages: {
          success: "Organizador atualizado com sucesso.",
          successCreate: "Organizador adicionado com sucesso.",
          errors: {
            required_fields: "Por favor preencha todos os campos",
            cpf_already_registerd: "CPF já cadastrado",
            invalid_telephone: "Telefone inválido",
            invalid_city: "Cidade inválida",
            invalid_address: "Endereço inválido",
            invalid_cep: "CEP inválido",
            invalid_cpf: "CPF inválido",
            invalid_cellphone: "Whatsapp inválido",
            invalid_name: "Nome inválido (mínimo 5 caracteres).",
            generic: "Algo deu errado, verifique os campos tente novamente.",
          },
        },
      },
      events: {
        sidebarTitle: "Eventos",
        sidebarItemRegister: "Cadastrar",
        sidebarItemList: "Lista",
        url: "/painel/eventos/",
        pageTitle: "Eventos",
        addNewButtonText: "Adicionar novo",
        userImportCsvButtonText: "Importar CSV",
        deleteSucces: "Evento removido com sucesso.",
        editSucces: "Evento atualizado com sucesso.",
        matches: "matches",
        match: "match",
        advancedFilters: {
          title: "Busca avançada",
          filterButtonText: "Filtrar",
          clearButtonText: "Limpar filtros",
          rangeStart: "Data inicial",
          rangeEnd: "Data final",
          userName: "Nome do usuário",
          eventName: "Nome do evento",
          emailUser: "E-mail do usuário",
          purchaseDate: "Data do compra",
          isActive: "Disponibilidade do evento",
        },
        userImportCsvModal: {
          okText: "Realizar upload",
          cancelText: "Cancelar",
          title: "Importar clientes por CSV",
          templateButtonOne: "Clique aqui",
          templateButtonTwo: "para realizar o download do template de importação.",
          selectFile: "Clique aqui ou arraste arquivos",
          selectFileDescription: "Tipo de arquivo permitido: .csv de até 2mb",
          errors: {
            invalidFileSize: "Arquivo muito grande, divida em um menor e tente novamente.",
            maxSelectedFiles: "Apenas um arquivo por vez.",
            generic: "Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.",
            genericUpload: "Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.",
            login_already_used: "Já existe um cliente com o login informado, verifique e tente novamente.",
            invalid_id: "Selecione a empresa.",
          },
          success: "Clientes(s) importado(s) com sucesso.",
        },
        dataTable: {
          columns: {
            title: {
              key: "title",
              title: "Nome do evento",
            },
            dateEvent: {
              key: "dateEventInitial",
              title: "Data do evento",
            },
            eventType: {
              key: "eventType",
              title: "Gênero musical",
            },
            totalQuantitySold: {
              key: "totalQuantitySold",
              title: "Ingressos vendidos",
            },
            remainingTicket: {
              key: "remainingTicket",
              title: "Ingressos restantes",
            },
            totalRevenues: {
              key: "totalRevenues",
              title: "Faturamento",
            },
            isActive: {
              key: "isActive",
              title: "Ativo",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
              purchases: "Compras",
            },
          },
        },
        errors: {
          generic: "Algo deu errado, tente novamente mais tarde.",
        },
      },
      eventDetails: {
        url: "/painel/eventos/",
        pageTitle: "Detalhes do evento",
        pageTitleAdd: "Cadastrar Evento",
        pageDescription: "Preencha os campos abaixo para editar um evento.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo evento",
        changePasswordPhrase: "Desejo alterar a senha do usuário",
        uploadPictureButton: "Upload de banner do evento",
        messages: {
          success: "Evento atualizado com sucesso.",
          successCreate: "Evento adicionado com sucesso.",
          errors: {
            required_fields: "Por favor preencha todos os campos",
            cpf_already_registerd: "CPF já cadastrado",
            email_already_used: "E-mail já cadastrado",
            invalid_password: "Senha inválida (mínimo de 6 caracteres).",
            invalid_email: "E-mail inválido, verifique e tente novamente.",
            invalid_telephone: "Telefone inválido",
            invalid_plan_expiry: "Data de vencimento inválida",
            invalid_city: "Cidade inválida",
            invalid_address: "Endereço inválido",
            invalid_cep: "CEP inválido",
            invalid_cpf: "CPF inválido",
            invalid_cellphone: "Whatsapp inválido",
            invalid_name: "Nome inválido (mínimo 5 caracteres).",
            user_not_found: "O email ou senha estão incorretos",
            generic: "Algo deu errado, verifique os campos tente novamente.",
            passwordsMismatch: "A senha e confirmação de senha não estão iguais.",
            passwordsRequired: "A senha e confirmação de senha são requeridas.",
            noPromoterFound: "Nenhum promotor encontrado pelo e-mail. Digite novamente.",
            expirate: "Data de expiração inválida",
            dateEventInitial: "Data de início do evento inválida",
            dateEventFinal: "Data de fim do evento inválida",
            dateSpotlightInitial: "Data de início de destaque do evento inválida",
            dateSpotlightFinal: "Data de fim de destaque do evento inválida",
            "invalid-date-event-initial": "Data de início do evento inválida",
            "invalid-date-event-final": "Data de fim do evento inválida",
            "invalid-ticket-maximum-capacity": "Quantidade disponíveis inválida",
            maximumCapacity: "Quantidade disponíveis inválida",
            invalid_pictures: "Insira uma imagem para o evento",
            not_possible_to_register_more_tickets:
              "Verifique a quantidade de ingressos disponíveis, deve ser inferior ou igual a capacidade máxima do evento",
            fillFieldEventName: "Preencha o campo Nome do evento",
            fillFieldEventDescription: "Preencha o campo Descrição",
            fillFieldEventArtiste: "Preencha o campo do Nome do artista",
            fillFieldEventNamePlace: "Preencha o campo Nome do local",
            fillFieldDateEventInitial: "Preencha a Data e horário de início",
            fillFieldDateEventFinal: "Preencha a Data e horário de finalização",
            fillFieldEventType: "Preencha a Categoria do ingresso",
            fillFieldPromoterId: "Preencha a Organizador",
            fillFieldAddress: "Preencha todos os campos do endereço",
            fillFieldMaximumCapacity: "Preencha o campo Capacidade máxima do evento",
            fillFieldTicketsTypesType: "Preencha o campo Categoria do ingresso",
            fillFieldTicketsTypesQuantity: "Preencha o campo Quantidade disponíveis",
            fillFieldTicketsTypesPrice: "Preencha o campo Valor selecionado",
            fillFieldPromoterInputValue: "Insira pelo menos um promotor ",
          },
        },
      },
      promoters: {
        sidebarTitle: "Promotores",
        sidebarItemRegister: "Cadastrar",
        sidebarItemList: "Lista",
        url: "/painel/promotores/",
        pageTitle: "Promotores",
        addNewButtonText: "Adicionar novo",
        userImportCsvButtonText: "Importar CSV",
        deleteSucces: "Promotor removido com sucesso.",
        matches: "matches",
        match: "match",
        advancedFilters: {
          title: "Busca avançada",
          filterButtonText: "Filtrar",
          clearButtonText: "Limpar filtros",
        },
        userImportCsvModal: {
          okText: "Realizar upload",
          cancelText: "Cancelar",
          title: "Importar clientes por CSV",
          templateButtonOne: "Clique aqui",
          templateButtonTwo: "para realizar o download do template de importação.",
          selectFile: "Clique aqui ou arraste arquivos",
          selectFileDescription: "Tipo de arquivo permitido: .csv de até 2mb",
          errors: {
            invalidFileSize: "Arquivo muito grande, divida em um menor e tente novamente.",
            maxSelectedFiles: "Apenas um arquivo por vez.",
            generic: "Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.",
            genericUpload: "Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.",
            login_already_used: "Já existe um cliente com o login informado, verifique e tente novamente.",
            invalid_id: "Selecione a empresa.",
          },
          success: "Promotor atualizado com sucesso.",
          successCreate: "Promotor adicionado com sucesso.",
        },
        dataTable: {
          columns: {
            photoUrl: {
              key: "photoUrl",
            },
            name: {
              key: "name",
              title: "Nome",
            },
            email: {
              key: "email",
              title: "E-mail",
            },
            birthday: {
              key: "birthday",
              title: "Data de nascimento",
            },
            gender: {
              key: "gender",
              title: "Gênero",
            },
            status: {
              key: "status",
              title: "Status",
            },
            cellphone: {
              key: "cellphone",
              title: "Celular",
            },
            cep: {
              key: "cep",
              title: "CEP",
            },
            city: {
              key: "city",
              title: "Cidade",
            },
            actions: {
              key: "id",
              title: "Ações",
              goToDetailsText: "Detalhes",
              removeText: "Remover",
            },
          },
        },
        errors: {
          generic: "Algo deu errado, tente novamente mais tarde.",
        },
      },
      purchases: {
        sidebarTitle: "Compras",
        sidebarItemList: "Lista",
        url: "/painel/compras/",
        pageTitle: "Compras",
        goToEvent: "Ver evento",
        advancedFilters: {
          title: "Busca avançada",
          filterButtonText: "Filtrar",
          clearButtonText: "Limpar filtros",
          totalQuantitySold: "Ingressos vendidos",
          remainingTicket: "Ingressos restantes",
          totalRevenues: "Faturamento total",
        },
        errors: {
          invalidFileSize: "Arquivo muito grande, divida em um menor e tente novamente.",
          maxSelectedFiles: "Apenas um arquivo por vez.",
          generic: "Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.",
          genericUpload: "Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.",
          login_already_used: "Já existe um cliente com o login informado, verifique e tente novamente.",
          invalid_id: "Selecione a empresa.",
        },
        successRefund: "Estorno realizado com sucesso.",
        refundInfo: "* O estorno deverá ser feito manualmente para pagamentos realizados com PIX e Boleto.",
        dataTable: {
          columns: {
            eventName: {
              key: "event",
              title: "Nome do evento",
            },
            userName: {
              key: "user",
              title: "Nome usuário",
            },
            paymentWay: {
              key: "payment",
              title: "Formas de pagamento",
            },
            userEmail: {
              key: "email",
              title: "E-mail usuário",
            },
            totalAmount: {
              key: "totalAmount",
              title: "Valor total",
            },
            ticket: {
              key: "ticket",
              title: "Ingressos",
            },
            createdAt: {
              key: "ticketDate",
              title: "Data da compra",
            },
            status: {
              key: "paymentStatus",
              title: "Status do pagamento",
            },
            actions: {
              key: "eventId",
              title: "Ações",
              goToDetailsText: "Detalhes evento",
              goToDetailsSimpleText: "Detalhes",
              removeText: "Remover",
              refund: {
                refundText: "Estornar",
                fullyRefundedText: "Estornado",
                popUp: {
                  title: "Você tem certeza?",
                },
              },
            },
          },
        },
      },
      userDetails: {
        url: "/painel/usuarios/",
        pageTitle: "Detalhes do usuário",
        pageTitleAdd: "Cadastrar cliente",
        pageDescription: "Preencha os campos abaixo para editar um usuário.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo usuário",
        changePasswordPhrase: "Desejo alterar a senha do usuário",
        messages: {
          success: "Usuário atualizado com sucesso.",
          successCreate: "Usuário adicionado com sucesso.",
          errors: {
            cellphone_already_registerd: "Whatsapp já cadastrado",
            cpf_already_registerd: "CPF já cadastrado",
            email_already_used: "E-mail já cadastrado",
            invalid_password: "Senha inválida (mínimo de 6 caracteres).",
            invalid_email: "E-mail inválido, verifique e tente novamente.",
            invalid_telephone: "Telefone inválido",
            invalid_plan_expiry: "Data de vencimento inválida",
            invalid_city: "Cidade inválida",
            invalid_address: "Endereço inválido",
            invalid_cep: "CEP inválido",
            invalid_cpf: "CPF inválido",
            invalid_cellphone: "Whatsapp inválido",
            invalid_name: "Nome inválido (mínimo 5 caracteres).",
            generic: "Algo deu errado, verifique os campos tente novamente.",
            passwordsMismatch: "A senha e confirmação de senha não estão iguais.",
            passwordsRequired: "A senha e confirmação de senha são requeridas.",
            name: "O nome inserido é inválido",
            password: "As senhas inseridas são inválidas. Devem ter no mínimo 6 caracteres",
            cellphone: "O Celular inserido é inválido!",
          },
        },
      },
      promoterDetails: {
        url: "/painel/promotores/",
        pageTitle: "Detalhes do promotor",
        pageTitleAdd: "Adicionar promotor",
        pageDescription: "Preencha os campos abaixo para editar um promotor.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo promotor",
        changePasswordPhrase: "Desejo alterar a senha do promotor",
        messages: {
          success: "Promotor atualizado com sucesso.",
          successCreate: "Usuário adicionado com sucesso.",
          errors: {
            generic: "Algo deu errado, verifique os campos tente novamente.",
            email: "E-mail inválido, verifique e tente novamente.",
            date: "A data inserida é inválida",
            passwordsMismatch: "A senha e confirmação de senha não estão iguais.",
            passwordsRequired: "A senha e confirmação de senha são requeridas.",
            email_already_used: "E-mail já cadastrado",
            name: "O nome inserido é inválido",
            password: "As senhas inseridas são inválidas. Devem ter no mínimo 6 caracteres",
            cellphone: "O Celular inserido é inválido!",
          },
        },
      },
      administratorDetails: {
        url: "/painel/administradores/",
        pageTitle: "Detalhes do administrador",
        pageTitleAdd: "Adicionar administrador",
        pageDescription: "Preencha os campos abaixo para editar um administrador.",
        pageDescriptionAdd: "Preencha os campos abaixo para adicionar um novo administrador",
        changePasswordPhrase: "Desejo alterar a senha do administrador",
        messages: {
          success: "Administrador atualizado com sucesso.",
          successCreate: "Administrador adicionado com sucesso.",
          errors: {
            name: "O nome inserido é inválido",
            password: "As senhas inseridas são inválidas. Devem ter no mínimo 6 caracteres",
            generic: "Algo deu errado, verifique os campos tente novamente.",
            email: "E-mail inválido, verifique e tente novamente.",
            date: "A data inserida é inválida",
            passwordsMismatch: "A senha e confirmação de senha não estão iguais.",
            passwordsRequired: "A senha e confirmação de senha são requeridas.",
            email_already_used: "E-mail já cadastrado",
          },
        },
      },
    },
  },
  forms: {
    user: {
      name: {
        label: "Nome completo",
      },
      nameFilter: {
        label: "Nome",
      },
      email: {
        label: "E-mail",
      },
      gender: {
        label: "Gênero",
      },
      bornAt: {
        label: "Data de nascimento",
      },
      cpf: {
        label: "CPF",
      },
      address: {
        label: "Endereço",
      },
      telephone: {
        label: "Telefone",
      },
      phone: {
        label: "Celular",
      },
      cellphone: {
        label: "WhatsApp",
      },
      hasTemi: {
        label: "Já prestou a prova TEMI",
      },
      hasUtiExpertise: {
        label: "Possui título de especialista UTI AMIB",
      },
      planExpiry: {
        label: "Vencimento",
      },
      category: {
        label: "Categorização",
      },
      hospitals: {
        label: "Hospitais que atua",
      },
      industry: {
        label: "Indústria",
      },
      password: {
        label: "Senha",
      },
      status: {
        label: "Status",
      },
      cep: {
        label: "CEP",
      },
      city: {
        label: "Cidade",
      },
      passwordConfirmation: {
        label: "Confirmação da senha",
      },
    },
    event: {
      artiste: {
        label: "Nome do artista",
      },
      title: {
        label: "Nome do evento",
      },
      eventType: {
        label: "Categoria do evento",
      },
      dateEvent: {
        label: "Data do evento",
      },
      description: {
        label: "Descrição",
      },
      date: {
        label: "Data e horário de início",
      },
      dateEnd: {
        label: "Data e horário de finalização",
      },
      dateSpotlight: {
        label: "Data e horário de início do destaque",
      },
      dateSpotlightEnd: {
        label: "Data e horário de fim do destaque",
      },
      expiration: {
        label: "Data de expiração",
      },
      cep: {
        label: "CEP",
      },
      uf: {
        label: "UF",
      },
      city: {
        label: "Cidade",
      },
      address: {
        label: "Endereço",
      },
      namePlace: {
        label: "Nome do local",
      },
      number: {
        label: "Número",
      },
      neighborhood: {
        label: "Bairro",
      },
      ticketType: {
        label: "Categoria do ingresso",
      },
      ticketAvailableAmount: {
        label: "Quantidade disponíveis",
      },
      ticketPrice: {
        label: "Valor selecionado",
      },
      isSpotlight: {
        label: "É um evento destaque?",
      },
      pauseSells: {
        label: "Pausar vendas?",
      },
      addPromoter: {
        label: "Insira o e-mail do promotor",
      },
      addImage: {
        label: "Clique ou arraste uma imagem aqui",
      },
      maximumCapacity: {
        label: "Capacidade máxima do evento",
      },
      organizer: {
        label: "Organizador",
      },
      isActive: {
        label: "O evento está ativo?",
      },
    },
    promoter: {
      name: {
        label: "Nome completo",
      },
      email: {
        label: "E-mail",
      },
      birthdate: {
        label: "Data de nascimento",
      },
      gender: {
        label: "Gênero",
      },
      cellphone: {
        label: "Celular",
      },
      password: {
        label: "Senha",
      },
      passwordConfirmation: {
        label: "Confirmação da senha",
      },
      masc: {
        label: "Confirmação da senha",
      },
      fem: {
        label: "Confirmação da senha",
      },
    },
    coupon: {
      codeFilter: {
        label: "Código",
      },
    },
    goBackButtonText: "Cancelar",
    submitButtonText: "Próximo",
  },
  enum: {
    profileType: {
      admin: "Administrador",
      user: "Usuário",
      operator: "Organizador",
      promoter: "Promotor",
    },
    accountType: {
      normalSavings: "Poupança",
      currentAccounts: "Conta corrente",
    },
  },
  pushNotification: {
    url: "/painel/push-notification/",
    pageTitle: "Criar notificações",
    sidebarTitle: "Notificações",
    success: "Notificação enviada com sucesso!",
    errors: {
      undefined: "Ops, algo deu errado. Por favor tente novamente mais tarde.",
      empty: "Selecione pelo menos um usuário para enviar a notificação.",
      generic: "Algo deu errado, verifique os campos tente novamente.",
    },
    form: {
      blockTitle: "Detalhes da notificação",
      types: {
        label: "Desejo enviar para",
      },
      title: {
        label: "Título da notificação",
      },
      description: {
        label: "Descrição",
      },
      hasImage: {
        label: "Incluir imagem no corpo da notificação?",
      },
      sendAll: {
        label: "Enviar para todos?",
      },
      image: {
        dragger: {
          title: "Imagem do corpo da notificação",
          tip: "Clique aqui ou arraste e solte uma imagem dentro da área tracejada",
        },
      },
      datatable: {
        title: "Usuários",
        name: {
          key: "name",
          label: "Nome",
        },
        email: {
          key: "email",
          label: "E-mail",
        },
        cellphone: {
          key: "cellphone",
          label: "Telefone",
        },
        createdAt: {
          key: "createdAt",
          label: "Data cadastro",
        },
      },
    },
    types: {
      one: {
        id: "1",
        name: "Clientes",
      },
      two: {
        id: "2",
        name: "Usuários X",
      },
      three: {
        id: "3",
        name: "Usuários Y",
      },
    },
    preview: {
      titlePlaceHolder: "Digite um título",
      descriptionPlaceHolder: "Digite uma descrição",
    },
  },
};
