import React from 'react';
import {
  Row,
  Col,
  message,
  Card,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SaveOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';
import { UtilsActions, OrganizerActions } from '../../redux/actions/index';
import {
  AuthSelectors, LoadingSelectors,
} from '../../redux/reducers';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AmountSelection from '../../../components/shared/AmountSelection/AmountSelection';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedCheckbox from '../../../components/shared/AdvancedCheckbox/AdvancedCheckbox';
import {
  AgencyTypeArray,
  AccountTypeArray,
  GetAgencyTypeAgencyMask,
  GetAgencyTypeAccountMask,
} from '../../enum/agencyType';
import { removeSpecialChars } from '../../utils/string';

class OrganizerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        nameFantasy: '',
        city: '',
        neighborhood: '',
        doc: '',
        zipcode: '',
        street: '',
        addressNumber: '',
        uf: '',
        cellphone: '',
        percentLuppi: 0,
        bankCode: '',
        bankAgency: '',
        accountType: '',
        bankAccount: '',
      },
      noCnpjselected: false,
      changeBankAccount: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { data: details, id } = props;

    if (!id || id === 'add' || state.prevDetails === details) {
      return null;
    }

    return {
      noCnpjselected: details.doc ? details.doc.length <= 11 : false,
      form: {
        name: details.name,
        nameFantasy: details.promoterData.nameFantasy,
        doc: details.doc,
        uf: details.address && details.address.uf,
        city: details.address && details.address.city,
        neighborhood: details.address && (details.address.neighborhood
          ? details.address.neighborhood
          : details.address.district),
        zipcode: details.address && details.address.zipCode,
        street: details.address && details.address.street,
        addressNumber: details.address && details.address.number,
        cellphone: details.cellphone,
        percentLuppi: parseInt(details.promoterData.percentLuppi, 10) || 0,
        bankCode: (details.subAccount && details.subAccount.bankCode) || '',
        bankAgency: (details.subAccount && details.subAccount.bankAgency) || '',
        accountType: (details.subAccount && details.subAccount.accountType) || '',
        bankAccount: (details.subAccount && details.subAccount.bankAccount) || '',
      },
      prevDetails: details,
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const { form, changeBankAccount } = this.state;
    const { id, submitFunction } = this.props;

    const requiredOnlyInAdd = (!id || id === 'add' || changeBankAccount)
      ? yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields'))
      : yup.string().nullable();

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      nameFantasy: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      zipcode: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      neighborhood: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      street: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      city: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      uf: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      addressNumber: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      doc: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      cellphone: yup.string().required(I18n.t('routes.panel.eventDetails.messages.errors.required_fields')),
      bankCode: requiredOnlyInAdd,
      bankAgency: requiredOnlyInAdd,
      accountType: requiredOnlyInAdd,
      bankAccount: requiredOnlyInAdd,
    });

    schema
      .validate(form, { abortEarly: true })
      .then(() => {
        if (changeBankAccount || !id || id === 'add') {
          const data = {
            ...form,
            doc: removeSpecialChars(form.doc),
            zipcode: removeSpecialChars(form.zipcode),
            cellphone: removeSpecialChars(form.cellphone),
            address: {
              zipCode: form.zipcode,
              countryName: form.country,
              uf: form.uf,
              city: form.city,
              district: form.neighborhood,
              street: form.street,
              number: form.addressNumber,
            },
            subAccount: {
              bankCode: form.bankCode,
              bankAgency: removeSpecialChars(form.bankAgency),
              accountType: form.accountType,
              bankAccount: form.bankAccount,
              responsibleSubAccount: {
                doc: removeSpecialChars(form.doc),
                cellphone: removeSpecialChars(form.cellphone),
                name: form.name,
              },
            },
          };
          submitFunction(id, data);
        } else {
          const data = {
            ...form,
            doc: removeSpecialChars(form.doc),
            zipcode: removeSpecialChars(form.zipcode),
            cellphone: removeSpecialChars(form.cellphone),
            address: {
              zipCode: form.zipcode,
              countryName: form.country,
              uf: form.uf,
              city: form.city,
              district: form.neighborhood,
              street: form.street,
              number: form.addressNumber,
            },
          };
          submitFunction(id, data);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  async getAddressByCep(cep) {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    if (!completeAddress.error) {
      this.fieldChange('uf', completeAddress.uf);
      this.fieldChange('city', completeAddress.localidade);
      this.fieldChange('street', completeAddress.logradouro);
      this.fieldChange('neighborhood', completeAddress.bairro);
      this.fieldChange('zipcode', completeAddress.cep);
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    if (name !== 'percentLuppi') {
      form[name] = value || '';
    } else {
      form[name] = value;
    }
    this.setState({ form });
  }

  render() {
    const {
      loading,
      id,
    } = this.props;

    const { form, noCnpjselected, changeBankAccount } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.organizerDetails.organizerSection')}</h5>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                    disabled={id && id !== 'add'}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.fantasyName.label')}
                    value={form && form.nameFantasy}
                    onChange={(val) => this.fieldChange('nameFantasy', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                {(form.doc || !id || id === 'add')
                  && (!noCnpjselected ? (
                    <Col span={8}>
                      <AdvancedMaskedInput
                        label={I18n.t('routes.panel.organizerDetails.form.docCnpj.label')}
                        value={form && form.doc}
                        onChange={(val) => this.fieldChange('doc', val)}
                        mask={I18n.t('shared.cnpjMask')}
                        disabled={id && id !== 'add'}
                      />
                    </Col>
                  ) : (
                    <Col span={8}>
                      <AdvancedMaskedInput
                        label={I18n.t('routes.panel.organizerDetails.form.docCpf.label')}
                        value={form && form.doc}
                        onChange={(val) => this.fieldChange('doc', val)}
                        mask={I18n.t('shared.cpfMask')}
                        disabled={id && id !== 'add'}
                      />
                    </Col>
                  ))}
                <Col span={8}>
                  {(!id || id === 'add') && (
                    <AdvancedCheckbox
                      label={I18n.t('routes.panel.organizerDetails.form.noCnpj.label')}
                      value={noCnpjselected}
                      onChange={(e) => this.setState({ noCnpjselected: e })}
                      customClass="checkbox-spaced"
                    />
                  )}
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.organizerDetails.form.zipcode.label')}
                    value={form && form.zipcode}
                    onChange={(val) => {
                      this.fieldChange('zipcode', val);
                      if (val && removeSpecialChars(val).length === 8) {
                        this.getAddressByCep(removeSpecialChars(val));
                      }
                    }}
                    mask={I18n.t('shared.zipCodeMask')}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.neighborhood.label')}
                    value={form && form.neighborhood}
                    onChange={(val) => this.fieldChange('neighborhood', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.street.label')}
                    value={form && form.street}
                    onChange={(val) => this.fieldChange('street', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.city.label')}
                    value={form && form.city}
                    onChange={(val) => this.fieldChange('city', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.uf.label')}
                    value={form && form.uf}
                    onChange={(val) => this.fieldChange('uf', val)}
                    maxLength={2}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.organizerDetails.form.addressNumber.label')}
                    value={form && form.addressNumber}
                    onChange={(val) => this.fieldChange('addressNumber', val)}
                  />
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col>
                      <h5>{I18n.t('routes.panel.organizerDetails.contactSection')}</h5>
                    </Col>
                  </Row>
                  <Row gutter={16} className="pt-2">
                    <Col span={12}>
                      <AdvancedMaskedInput
                        label={I18n.t('routes.panel.organizerDetails.form.cellphone.label')}
                        value={form && form.cellphone}
                        onChange={(val) => this.fieldChange('cellphone', val)}
                        mask={I18n.t('shared.phoneMask')}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Card>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h5 className="center-title">
                          {I18n.t('routes.panel.organizerDetails.settingsSection')}
                        </h5>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24} className="d-flex">
                        {true && (
                          <AmountSelection
                            suffix={I18n.t('application.percent')}
                            value={form && form.percentLuppi ? form.percentLuppi : 0}
                            description={I18n.t(
                              'routes.panel.organizerDetails.form.luppiCommission.label',
                            )}
                            onChange={(val) => this.fieldChange('percentLuppi', val)}
                            percentage
                            min={0}
                            number={1}
                            max={40}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col span={20}>
                  <h5>{I18n.t('routes.panel.organizerDetails.subAccountSection')}</h5>
                </Col>
                {/* <Col span={4}>
                  <AdvancedButton
                    text="Alterar dados banc."
                    onClick={() => this.setState({ showModal: true})}
                    icon={<SaveOutlined />}
                  />
                </Col> */}
              </Row>
              {(id && id !== 'add') && (
                <Row gutter={16}>
                  <Col span={20}>
                    <AdvancedCheckbox
                      label="Deseja alterar os dados bancários?"
                      value={changeBankAccount}
                      onChange={() => this.setState({ changeBankAccount: !changeBankAccount })}
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedSelect
                    options={AgencyTypeArray}
                    label={I18n.t('routes.panel.organizerDetails.form.bank.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('bankCode', val)}
                    disabled={((loading && loading > 0) || (id && id !== 'add')) && !changeBankAccount}
                    hasValue
                    value={form && form.bankCode}
                  />
                </Col>

                <Col span={6}>
                  <AdvancedSelect
                    options={AccountTypeArray}
                    label={I18n.t('routes.panel.organizerDetails.form.accountType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('accountType', val)}
                    disabled={((loading && loading > 0) || (id && id !== 'add')) && !changeBankAccount}
                    hasValue
                    value={form && form.accountType}
                    disableSearch
                  />
                </Col>

                {form && form.bankCode && (
                  <>
                    <Col span={6}>
                      {(id && id !== 'add') && !changeBankAccount ? (
                        <AdvancedInput
                          label={I18n.t('routes.panel.organizerDetails.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          onChange={(val) => this.fieldChange('bankAgency', val)}
                          disabled={(id && id !== 'add') && !changeBankAccount}
                        />
                      ) : (
                        <AdvancedMaskedInput
                          label={I18n.t('routes.panel.organizerDetails.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          onChange={(val) => this.fieldChange('bankAgency', val)}
                          mask={GetAgencyTypeAgencyMask(form && form.bankCode)}
                        />
                      )}
                    </Col>
                    <Col span={6}>
                      {(id && id !== 'add') && !changeBankAccount ? (
                        <AdvancedInput
                          label={I18n.t(
                            'routes.panel.organizerDetails.form.bankAccount.label',
                          )}
                          value={form && form.bankAccount}
                          onChange={(val) => this.fieldChange('bankAccount', val)}
                          disabled={(id && id !== 'add') && !changeBankAccount}
                        />
                      ) : (
                        <AdvancedMaskedInput
                          label={I18n.t(
                            'routes.panel.organizerDetails.form.bankAccount.label',
                          )}
                          value={form && form.bankAccount}
                          onChange={(val) => this.fieldChange('bankAccount', val)}
                          mask={GetAgencyTypeAccountMask(form && form.bankCode)}
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>

              {form && form.bankCode && (
                <p className="input-observation">
                  {I18n.t('routes.panel.organizerDetails.form.bankAccount.observation')}
                </p>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.organizers.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizerDetails: (id) => dispatch(OrganizerActions.getOrganizerDetails(id)),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizerForm);
