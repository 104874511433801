import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';
import HandleErrors from '../../services/handleErrors';

import { addLoading, removeLoading } from './loading';
import { ProfileType } from '../../enum/profileType';

import * as UserActions from './user';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (authData) => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(UserActions.cleanUser());
  AuthService.reset();
  AuthService.redirectToLogin();
};

export const authenticate = (userData, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    userData = {
      ...userData,
    };
    const auth = await AuthRequests.auth(userData);
    AuthService.create(auth);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth,
    });
    if (callback && typeof callback === 'function') {
      callback();
    }
    return navigate(I18n.t('routes.panel.users.url'));
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};

export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await AuthRequests.me();
    if (data.profileType !== ProfileType.ADMIN) {
      dispatch(logout());
      return message.error(I18n.t('shared.noAccess'));
    }
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
