import {
  addLoading,
  removeLoading,
} from './loading';
import UserRequests from '../../api/user';
import { ProfileType } from '../../enum/profileType';
import HandleErrors from '../../services/handleErrors';

export const ACTION_SAVE_PROMOTERS_PAGINATED = 'ACTION_SAVE_PROMOTERS_PAGINATED';
export const ACTION_SAVE_PROMOTER_DETAILS = 'ACTION_SAVE_PROMOTER_DETAILS';

export const ACTION_CLEAN_PROMOTER = 'CLEAN_PROMOTER';

export const getPromotersPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: parameters.pageSize || 10,
    offset: parameters.offset || 0,
    orderBy: 'createdAt',
    desc: true,
  };

  try {
    const promotersPaginated = await UserRequests.getUsersPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_PROMOTERS_PAGINATED,
      payload: promotersPaginated,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPromoterDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await UserRequests.getUserDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_PROMOTER_DETAILS,
      payload: data,
    });
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getPromoterByEmail = (email) => async (dispatch) => {
  dispatch(addLoading());
  const parameters = {
    email,
    profileType: ProfileType.PROMOTER,
  };

  try {
    const promotersPaginated = await UserRequests.getUsersPaginated(parameters);
    if (promotersPaginated.count > 0) return promotersPaginated.rows[0];
    return null;
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePromoter = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      userId: id,
    };

    await UserRequests.update(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const createPromoter = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.PROMOTER,
      genderInterest: 1,
      maritalStatus: 1,
      matchEnabled: true,
      description: 'Promotor',
    };
    await UserRequests.createUser(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const removePromoter = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.delete(id);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    HandleErrors(err);
  } finally {
    dispatch(removeLoading());
  }
};
