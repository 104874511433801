export const PaymentStatus = {
  CREATE: 1,
  REFUSED: 2,
  CREATED: 3,
  SCORE_VALIDATED: 4,
  CAPTURED: 5,
  CANCELED: 6,
  PARTIALLY_REFUNDED: 7,
  FULLY_REFUNDED: 8,
  PAID: 9,
  EXPIRED: 10,
  PENDING: 11,
  PARTIALLY_PAID: 12,
  CHARGEBACK: 13,
  BLOCKED: 14,
  APPROVED: 15,
};
export const TicketTypeEnum = {
  1: { id: PaymentStatus.CREATE, name: "Criar" },
  2: { id: PaymentStatus.REFUSED, name: "Recusado" },
  3: { id: PaymentStatus.CREATED, name: "Criado" },
  4: { id: PaymentStatus.SCORE_VALIDATED, name: "Validado" },
  5: { id: PaymentStatus.CAPTURED, name: "Capturado" },
  6: { id: PaymentStatus.CANCELED, name: "Cancelado" },
  7: { id: PaymentStatus.PARTIALLY_REFUNDED, name: "Reembolso parcial" },
  8: { id: PaymentStatus.FULLY_REFUNDED, name: "Reembolçado" },
  9: { id: PaymentStatus.PAID, name: "Pago" },
  10: { id: PaymentStatus.EXPIRED, name: "Expirado" },
  11: { id: PaymentStatus.PENDING, name: "Pendente" },
  12: { id: PaymentStatus.PARTIALLY_PAID, name: "Parcialmente pago" },
  13: { id: PaymentStatus.CHARGEBACK, name: "Extornado" },
  14: { id: PaymentStatus.BLOCKED, name: "Bloqueado" },
  15: { id: PaymentStatus.APPROVED, name: "Aprovado" },
};
export const PaymentStatusSelector = [
  { id: PaymentStatus.REFUSED, type: "Recusado" },
  { id: PaymentStatus.CREATED, type: "Criado" },
  { id: PaymentStatus.SCORE_VALIDATED, type: "Validado" },
  { id: PaymentStatus.CAPTURED, type: "Capturado" },
  { id: PaymentStatus.CANCELED, type: "Cancelado" },
  { id: PaymentStatus.PARTIALLY_REFUNDED, type: "Estorno parcial" },
  { id: PaymentStatus.FULLY_REFUNDED, type: "Estornado" },
  { id: PaymentStatus.PAID, type: "Pago" },
  { id: PaymentStatus.EXPIRED, type: "Expirado" },
  { id: PaymentStatus.PENDING, type: "Pendente" },
  { id: PaymentStatus.PARTIALLY_PAID, type: "Parcialmente pago" },
];
