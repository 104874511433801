import React from 'react';
import {
  UserOutlined, TeamOutlined, LeftOutlined, RightOutlined, AuditOutlined, NotificationOutlined, ShoppingCartOutlined,
  LockOutlined,
  TagOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Row,
  Col,
  notification,
  Button,
} from 'antd';
import { connect } from 'react-redux';
import {
  I18n,
} from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';

import * as AuthSelectors from '../redux/reducers/auth';
import { AuthActions } from '../redux/actions';

import { ReactComponent as LogoutIcon } from '../assets/img/logout.svg';

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
    if (!this.props.me) {
      this.props.getMe();
    }
  }

  getCurrent = () => window.location.pathname

  toggle = () => {
    const { collapsed } = this.state;
    this.setState(
      {
        collapsed: !collapsed,
      },
    );
  };

  openNotification() {
    try {
      const { logout } = this.props;
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            logout();
            notification.close(key);
          }}
        >
          {I18n.t('shared.logout.confirmButton')}
        </Button>
      );
      notification.open({
        message: I18n.t('shared.logout.confirmation'),
        btn,
        key,
      });
    } catch (err) {
      //
    }
  }

  render() {
    const {
      title,
      container,
      id,
      me,
    } = this.props;
    const { Header, Sider } = Layout;

    return (
      <Layout className="panel__layout">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
        >
          <div className="logo" />

          <Menu
            theme="dark"
            mode="inline"
            className="panel__layout__menu"
            selectedKeys={[this.getCurrent()]}
          >

            <div className="panel__layout__menu__img__wrapper">
              <img
                alt="sidebar logo"
                className="panel__layout__menu__img"
                src="/assets/img/drawable-xhdpi/logo_primary.png"
              />
            </div>
            <Menu.Item key={`${I18n.t('routes.panel.users.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.users.url')}`}
                style={{ verticalAlign: 'middle' }}
              >
                <UserOutlined />
                <span>
                  {I18n.t('routes.panel.users.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('routes.panel.promoters.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.promoters.url')}`}
                className="panel__layout__menu__item__link"
              >
                <TeamOutlined />
                <span>
                  {I18n.t('routes.panel.promoters.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('routes.panel.administrators.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.administrators.url')}`}
                className="panel__layout__menu__item__link"
              >
                <LockOutlined />
                <span>
                  {I18n.t('routes.panel.administrators.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('routes.panel.organizers.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.organizers.url')}`}
                className="panel__layout__menu__item__link"
              >
                <UserOutlined />
                <span>
                  {I18n.t('routes.panel.organizers.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('routes.panel.events.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.events.url')}`}
                className="panel__layout__menu__item__link"
              >
                <AuditOutlined />
                <span>
                  {I18n.t('routes.panel.events.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('routes.panel.purchases.url')}`}>
              <Link
                to={`${I18n.t('routes.panel.purchases.url')}`}
                className="panel__layout__menu__item__link"
              >
                <ShoppingCartOutlined />
                <span>
                  {I18n.t('routes.panel.purchases.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={I18n.t('routes.panel.discountCoupon.url')}>
              <Link
                to={`${I18n.t('routes.panel.discountCoupon.url')}`}
                className="panel__layout__menu__item__link"
              >
                <TagOutlined />
                <span>
                  {I18n.t('routes.panel.discountCoupon.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${I18n.t('pushNotification.url')}`}>
              <Link
                to={`${I18n.t('pushNotification.url')}`}
                className="panel__layout__menu__item__link"
              >
                <NotificationOutlined />
                <span>
                  {I18n.t('pushNotification.sidebarTitle')}
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="panel__layout__wrapper">
          <Header className="panel__layout__header">
            <Row>
              <Col span={18}>
                {this.state.collapsed
                  ? (
                    <RightOutlined
                      className="panel__trigger_button"
                      onClick={this.toggle}
                      style={{ color: '#fc1f55' }}
                    />
                  )
                  : (
                    <LeftOutlined
                      className="panel__trigger_button"
                      onClick={this.toggle}
                      style={{ color: '#fc1f55' }}
                    />
                  )}
              </Col>
              <Col
                span={6}
              >
                <div className="panel__layout__header__user-info">
                  <span>{I18n.t('shared.hi')} </span>
                  <span className="panel__layout__header__user-info__user-name">{me && me.name}</span>
                  <LogoutIcon
                    onClick={() => this.openNotification()}
                    text={I18n.t('shared.logout.menuText')}
                    style={{ cursor: 'pointer', fill: '#fc1f55' }}
                  />

                </div>
              </Col>
            </Row>
          </Header>

          {me && (id ? React.cloneElement(container, { id }) : container)}

          <div className="panel__layout__content__footer">
            {I18n.t('application.footer.poweredBy')}&nbsp;
            <a href={I18n.t('application.footer.poweredByBrandUrl')}>
              {I18n.t('application.footer.poweredByBrand')}
            </a> - {I18n.t('application.version.title')}: {I18n.t('application.version.number')}
          </div>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: (user) => dispatch(AuthActions.logout(user)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Panel,
);
