import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

const HandleErrors = (err) => {
  const errors = I18n.t('errors');
  if (err && err.message && errors[err.message] !== undefined) {
    return message.error((`${errors[err.message]}`));
  }
  if (err && err.response && err.response.options && errors[err.response.options] !== undefined) {
    return message.error((`${errors[err.response.options]}`));
  }
  if (err && err.response && err.response.response
      && err.response.response.error && errors[err.response.response.error] !== undefined) {
    return message.error((`${errors[err.response.response.error]}`));
  }
  return message.error(I18n.t('errors.generic'));
};

export default HandleErrors;
