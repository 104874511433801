import getInstance from './config';

export default class PushNotificationApi {
  static async getUsersForPushPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async createNotification(params) {
    const instance = await getInstance();
    const { data } = await instance.post('/notification/adm', params);
    return data;
  }
}
